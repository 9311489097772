import { useSchoolStore } from "@stores/school.store";
import { useThemeStore } from "@stores/theme.store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { routerRegister } from "@utils/router";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

export const Application = () => {
  const { firstLoad } = useSchoolStore((state) => state);
  const { addListenerThemeMode, removeListenerThemeMode } = useThemeStore((state) => state);

  useEffect(() => {
    firstLoad();
  }, [firstLoad]);

  useEffect(() => {
    addListenerThemeMode();
    return () => {
      removeListenerThemeMode();
    };
  }, [addListenerThemeMode, removeListenerThemeMode]);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={routerRegister} />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
};
