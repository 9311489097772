import { create } from "zustand";

interface SchoolStore {
  name?: string;
  moto?: string;
  logo?: string;
  updateInfo(data: { name?: string; moto?: string; logo?: string }): void;
  clearInfo(): void;
  firstLoad(): void;
}

export const useSchoolStore = create<SchoolStore>((set) => ({
  name: undefined,
  moto: undefined,
  logo: undefined,
  updateInfo: (data) => {
    localStorage.setItem('school-name', data.name ?? '')
    localStorage.setItem('school-moto', data.moto ?? '')
    localStorage.setItem('school-logo', data.logo ?? '')
    set({ ...data });
  },
  clearInfo: () => {
    set({ name: undefined, moto: undefined, logo: undefined });
  },
  firstLoad: () => {
    set({
      name: localStorage.getItem('school-name') ?? undefined,
      moto: localStorage.getItem('school-moto') ?? undefined,
      logo: localStorage.getItem('school-logo') ?? undefined,
    });
  }
}));
