import { PocketTransfer } from "@components/resolvers/pocket.resolvers";
import axios from "axios";
import { BaseService } from "./base.service";
import { BaseResponse, BaseResponseExternal } from "./dtos/base.dto";
import { BillsResponse, PaymentPocketRequest, PocketHistoryResponse, PocketResponse, VirtualAccountReportResponse, VirtualAccountRequest } from "./dtos/finance.dto";

interface FinanceServiceInterface {
  queries: {
    GET_FINANCE_BILLS: string;
    GET_FINANCE_POCKET: string;
    GET_FINANCE_BILLS_PAID_OF: string;
    GET_FINANCE_CHECKING_ACCOUNT: string;
    GET_FINANCE_POCKET_HISTORY: string;
    GET_FINANCE_VIRTUAL_ACCOUNT_REPORT: string;
  };
  bills(limit: string): Promise<BillsResponse>;
  billsPaidOf(): Promise<BillsResponse>;
  checkingAccount(payload: { range: "WEEK" | "MONTH"; type: "ALL" | "IN" | "OUT" }): Promise<PocketHistoryResponse>;
  pocket(): Promise<PocketResponse>;
  pocketTransfer(payload: PocketTransfer): Promise<BaseResponse<number>>;
  pocketHistory(
    pocket: string,
    payload: {
      range: "WEEK" | "MONTH";
      type: "ALL" | "IN" | "OUT";
    }
  ): Promise<PocketHistoryResponse>;
  virtualAccountReport(payload: { range: "10" | "20" | "50"; status: "ALL" | "PENDING" | "COMPLETED" | "EXPIRED" }): Promise<VirtualAccountReportResponse>;
  virtualAccountCancel(id: string): Promise<BaseResponse>;
  virtualAccountRequest(payload: VirtualAccountRequest): Promise<BaseResponse>;
  paymentWithPocket(payload: PaymentPocketRequest): Promise<BaseResponse>;
}
export const FinanceService: FinanceServiceInterface = {
  queries: {
    GET_FINANCE_BILLS: "GET_FINANCE_BILLS",
    GET_FINANCE_BILLS_PAID_OF: "GET_FINANCE_BILLS_PAID_OF",
    GET_FINANCE_CHECKING_ACCOUNT: "GET_FINANCE_CHECKING_ACCOUNT",
    GET_FINANCE_POCKET: "GET_FINANCE_POCKET",
    GET_FINANCE_POCKET_HISTORY: "GET_FINANCE_POCKET_HISTORY",
    GET_FINANCE_VIRTUAL_ACCOUNT_REPORT: "GET_FINANCE_VIRTUAL_ACCOUNT_REPORT",
  },
  bills: async (limit: string) => {
    try {
      const request = await BaseService.request().get<BillsResponse>("/finance/bills", { params: { limit } });
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  billsPaidOf: async () => {
    try {
      const request = await BaseService.request().get<BillsResponse>("/finance/bills-paid-of");
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  checkingAccount: async (payload: { range: "WEEK" | "MONTH"; type: "ALL" | "IN" | "OUT" }) => {
    try {
      const request = await BaseService.request().get<PocketHistoryResponse>("/finance/checking-account", { params: payload });
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  pocket: async () => {
    try {
      const request = await BaseService.request().get<BillsResponse>("/finance/pocket");
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  pocketTransfer: async (payload: PocketTransfer) => {
    try {
      const request = await BaseService.request().post<BaseResponse<number>>(`/finance/pocket/transfer`, payload);
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  pocketHistory: async (
    pocket: string,
    payload: {
      range: "WEEK" | "MONTH";
      type: "ALL" | "IN" | "OUT";
    }
  ) => {
    try {
      const request = await BaseService.request().get<BillsResponse>(`/finance/pocket/${pocket}/history`, { params: payload });
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  virtualAccountReport: async (payload: { range: "10" | "20" | "50"; status: "ALL" | "PENDING" | "COMPLETED" | "EXPIRED" }) => {
    try {
      const request = await BaseService.request().get<BillsResponse>(`/finance/virtual-accounts-report`, { params: payload });
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  virtualAccountCancel: async (id: string) => {
    try {
      const request = await BaseService.request().get<BillsResponse>(`/finance/virtual-accounts-cancel/${id}`);
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  virtualAccountRequest: async (payload: VirtualAccountRequest) => {
    try {
      const request = await axios.post<
        BaseResponseExternal<{
          trxAmount: number;
          nis: string;
          customerName: string;
          virtualAccount: string;
          datetimeExpired: Date;
          trxID: string;
          jenis: string;
          requestPayment: string;
        }>
      >("https://almatuq.aplikasi-pesantren.id/api/tagihan/submit_va_tagihan", payload, {
        headers: {
          token: "$2y$12$MGmA4HEoSMPEdMQCEaUDCePe13Qk5mrKhMUu2rgejRBg2MN8GsJHq",
        },
      });
      return {
        success: request.data.status,
        message: request.data.message,
        data: request.data.data,
      };
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  paymentWithPocket: async (payload: PaymentPocketRequest) => {
    try {
      const request = await axios.post<BaseResponseExternal>("https://almatuq.aplikasi-pesantren.id/api/tagihan/submit_bayar_tagihan", payload, {
        headers: {
          token: "$2y$12$MGmA4HEoSMPEdMQCEaUDCePe13Qk5mrKhMUu2rgejRBg2MN8GsJHq",
        },
      });
      return {
        success: request.data.status,
        message: request.data.message,
        data: null,
      };
    } catch (error: any) {
      throw new Error("Terjadi Kesalahan Server!");
    }
  },
};
