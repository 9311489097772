// UI
export const androidChangeThemeStatusBar = (
  color: string,
  mode: "dark" | "light"
) => {
  if (typeof window.changeThemeStatusBar !== "undefined")
    window.changeThemeStatusBar.postMessage(
      JSON.stringify({
        color: color,
        mode: mode,
      })
    );
};

// Scan QR
export const androidScanQRCode = () => {
  if (typeof window.scanQRCode !== "undefined")
    window.scanQRCode.postMessage("");
};

// Firebase
export const androidTokenFirebase = () => {
  if (typeof window.tokenFcm !== "undefined") window.tokenFcm.postMessage("");
};

// BIOMETRIC
export const androidSetupBiometric = (type: "register" | "remove") => {
  // if (typeof window.setupBiometric !== "undefined")
  //   window.setupBiometric.postMessage(type);
};

export const androidLoginBiometric = () => {
  // if (typeof window.loginBiometric !== "undefined")
  //   window.loginBiometric.postMessage("");
};

export const androidSaveDataBiomatric = (payload: {
  username: string;
  password: string;
}) => {
  // if (typeof window.saveDataBiomatric !== "undefined")
  //   window.saveDataBiomatric.postMessage(JSON.stringify(payload));
};

export const androidIsAvailableBiomatric = () => {
  // if (typeof window.isAvailableBiomatric !== "undefined")
  //   window.isAvailableBiomatric.postMessage("");
};

// Inbox
export const androidUnreadInbox = () => {
  if (typeof window.unreadInbox !== "undefined")
    window.unreadInbox.postMessage("");
};
export const androidReadInbox = (id: string) => {
  if (typeof window.readInbox !== "undefined") window.readInbox.postMessage(id);
};
export const androidRemoveInbox = (id: string) => {
  if (typeof window.removeInbox !== "undefined") window.removeInbox.postMessage(id);
};
export const androidListInbox = (filter: string) => {
  if (typeof window.listInbox !== "undefined")
    window.listInbox.postMessage(filter);
};
