import { BaseLayout } from "@components/generals/base_layout";
import { Header } from "@components/generals/header";
import { useThemeStore } from "@stores/theme.store";
import { linkPage } from "@utils/router";
import { useEffect } from "react";
import {
  FcBusinessContact,
  FcGraduationCap,
  FcHighPriority,
  FcInvite,
  FcMoneyTransfer,
  FcPackage,
  FcSurvey,
} from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Biodata } from "@assets/images/vector/profileuser.svg";
import { ReactComponent as Absensi } from "@assets/images/vector/deadlinestopwatch.svg";
import { ReactComponent as Prestasi } from "@assets/images/vector/medalreward.svg";
import { ReactComponent as Tagihan } from "@assets/images/vector/bill.svg";
import { ReactComponent as Kantong } from "@assets/images/vector/wallet.svg";
import { ReactComponent as Lainnya } from "@assets/images/vector/more.svg";
import { ReactComponent as Kesehatan } from "@assets/images/vector/medicalhistory.svg";
import { ReactComponent as Pelanggaran } from "@assets/images/vector/warning.svg";
import { ReactComponent as Uang } from "@assets/images/vector/money.svg";
import { ReactComponent as Tahfidz } from "@assets/images/vector/book.svg";

export const OtherMenusPage = () => {
  const navigate = useNavigate();
  const { setFooter, setHeader, setAppBar, setBottomNavigation } =
    useThemeStore((state) => state);

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Menu Lainnya",
      buttonBack: true,
      avatar: false,
    });
    setAppBar({ show: true, title: "Menu Lainnya", leading: "back" });
    setBottomNavigation("hidden");
  }, [setFooter, setHeader, setAppBar, setBottomNavigation]);

  return (
    <BaseLayout>
      <Header height={80} classOverlay="rounded-0" />
      {/* <div className="content mb-3 mt-5 tw-relative">
        <h5 className="float-start font-16 font-500 ">Rekomendasi Menu</h5>
        <div className="clearfix"></div>
      </div> */}

      {/* <div className="tw-mx-4 tw-grid tw-grid-cols-4 tw-gap-x-3 tw-gap-y-5 tw-mb-8 tw-relative"> */}
      {/* <div onClick={() => navigate(linkPage.MARKETPLACE_PRODUCT)} className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer">
          <FcShop className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Al-Barokah</span>
        </div> */}
      {/* <div onClick={() => navigate(linkPage.FINANCE_VIRTUAL_ACCOUNT_REQUEST)} className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer">
          <FcFeedback className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Request VA</span>
        </div> */}
      {/* <div onClick={() => navigate(linkPage.PERMIT_FORM)} className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer">
          <FcOvertime className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Request Izin</span>
        </div> */}
      {/* <div
          onClick={() => navigate(linkPage.SCHOOL_STUDENT_BIO)}
          className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer"
        >
          <Biodata className="mx-auto tw-mb-2" width={38} height={38} />
          <span className="tw-text-xs lh-1">Biodata</span>
        </div>
        <div
          onClick={() => navigate(linkPage.SCHOOL_STUDENT_ABSENCES)}
          className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer"
        >
          <Absensi className="mx-auto tw-mb-2" width={38} height={38} />
          <span className="tw-text-xs lh-1">Absensi</span>
        </div>
        <div
          onClick={() => navigate(linkPage.SCHOOL_STUDENT_ACHIEVEMENT)}
          className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer"
        >
          <Prestasi className="mx-auto tw-mb-2" width={38} height={38} />
          <span className="tw-text-xs lh-1">Prestasi</span>
        </div>
      </div> */}

      {/* <div className="content mb-3 tw-relative">
        <h5 className="float-start font-16 font-500 ">Al-Barokah Tijaroh Ummah</h5>
        <div className="clearfix"></div>
      </div> */}

      {/* <div className="tw-mx-4 tw-grid tw-grid-cols-4 tw-gap-x-3 tw-gap-y-5 tw-mb-8 tw-relative">
        <div onClick={() => navigate(linkPage.MARKETPLACE_PRODUCT)} className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer">
          <FcShop className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Al-Barokah</span>
        </div>
        <div onClick={() => navigate(linkPage.MARKETPLACE_SETTING)} className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer">
          <FcCurrencyExchange className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Setting Limit Belanja</span>
        </div>
      </div> */}
      {/* 
      <div className="content mb-3 tw-relative">
        <h5 className="float-start font-16 font-500 ">Pembayaran</h5>
        <div className="clearfix"></div>
      </div>

      <div className="tw-mx-4 tw-grid tw-grid-cols-4 tw-gap-x-3 tw-gap-y-5 tw-mb-8 tw-relative">
        <div onClick={() => navigate(linkPage.FINANCE_VIRTUAL_ACCOUNT_REQUEST)} className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer">
          <FcFeedback className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Request VA</span>
        </div>
        <div onClick={() => navigate(linkPage.FINANCE_VIRTUAL_ACCOUNT_REPORT)} className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer">
          <FcDocument className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Laporan VA</span>
        </div>
      </div> */}

      <div className="content mb-2 mt-5 tw-relative">
        <h5 className="float-start font-16 font-500 ">Sekolah</h5>
        <div className="clearfix"></div>
      </div>

      <div className="tw-mx-4 tw-grid tw-grid-cols-4 tw-gap-x-3 tw-gap-y-5 tw-mb-8 tw-relative">
        <div
          onClick={() => navigate(linkPage.SCHOOL_STUDENT_BIO)}
          className="text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer"
        >
          <Biodata
            className="mx-auto tw-mb-2 bg-theme rounded-m tw-shadow tw-py-2 tw-px-2"
            width={38}
            height={38}
            style={{ boxSizing: "content-box" }}
          />
          <span className="tw-text-xs lh-1">Biodata</span>
        </div>
        <div
          onClick={() => navigate(linkPage.SCHOOL_STUDENT_ABSENCES)}
          className="text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer"
        >
          <Absensi
            className="mx-auto tw-mb-2 bg-theme rounded-m tw-shadow tw-py-2 tw-px-2"
            width={38}
            height={38}
            style={{ boxSizing: "content-box" }}
          />
          <span className="tw-text-xs lh-1">Absensi</span>
        </div>
        <div
          onClick={() => navigate(linkPage.SCHOOL_STUDENT_ACHIEVEMENT)}
          className="text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer"
        >
          <Prestasi
            className="mx-auto tw-mb-2 bg-theme rounded-m tw-shadow tw-py-2 tw-px-2"
            width={38}
            height={38}
            style={{ boxSizing: "content-box" }}
          />
          <span className="tw-text-xs lh-1">Prestasi</span>
        </div>
        <div
          onClick={() => navigate(linkPage.SCHOOL_STUDENT_HEALTH)}
          className="text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer"
        >
          <Kesehatan
            className="mx-auto tw-mb-2 bg-theme rounded-m tw-shadow tw-py-2 tw-px-2"
            width={38}
            height={38}
            style={{ boxSizing: "content-box" }}
          />
          <span className="tw-text-xs lh-1">Kesehatan</span>
        </div>
        <div
          onClick={() => navigate(linkPage.SCHOOL_STUDENT_VIOLATIONS)}
          className="text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer"
        >
          <Pelanggaran
            className="mx-auto tw-mb-2 bg-theme rounded-m tw-shadow tw-py-2 tw-px-2"
            width={38}
            height={38}
            style={{ boxSizing: "content-box" }}
          />
          <span className="tw-text-xs lh-1">Pelanggaran</span>
        </div>

        <div
          onClick={() => navigate(linkPage.SCHOOL_STUDENT_TAHFIDZ)}
          className="text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer"
        >
          <Tahfidz
            className="mx-auto tw-mb-2 bg-theme rounded-m tw-shadow tw-py-2 tw-px-2"
            width={38}
            height={38}
            style={{ boxSizing: "content-box" }}
          />
          <span className="tw-text-xs lh-1">Tahfidz</span>
        </div>
      </div>

      <div className="content mb-2 tw-relative">
        <h5 className="float-start font-16 font-500 ">Keuangan</h5>
        <div className="clearfix"></div>
      </div>

      <div className="tw-mx-4 tw-grid tw-grid-cols-4 tw-gap-x-3 tw-gap-y-5 tw-mb-8 tw-relative">
        <div
          onClick={() => navigate(linkPage.FINANCE_POCKET)}
          className="text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer"
        >
          <Kantong
            className="mx-auto tw-mb-2 bg-theme rounded-m tw-shadow tw-py-2 tw-px-2"
            width={38}
            height={38}
            style={{ boxSizing: "content-box" }}
          />
          <span className="tw-text-xs lh-1">Kantong</span>
        </div>
        <div
          onClick={() => navigate(linkPage.FINANCE_BILL)}
          className="text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer"
        >
          <Tagihan
            className="mx-auto tw-mb-2 bg-theme rounded-m tw-shadow tw-py-2 tw-px-2"
            width={38}
            height={38}
            style={{ boxSizing: "content-box" }}
          />
          <span className="tw-text-xs lh-1">Kewajiban</span>
        </div>
        {/* <div onClick={() => navigate(linkPage.FINANCE_CHECKING_ACCOUNT)} className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer">
          <FcRules className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Rekening Koran</span>
        </div> */}
      </div>

      {/* <div className="content mb-3 tw-relative">
        <h5 className="float-start font-16 font-500 ">Perizinan</h5>
        <div className="clearfix"></div>
      </div>

      <div className="tw-mx-4 tw-grid tw-grid-cols-4 tw-gap-x-3 tw-gap-y-5 tw-mb-8 tw-relative">
        <div onClick={() => navigate(linkPage.PERMIT_FORM)} className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer">
          <FcOvertime className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Request Izin</span>
        </div>
        <div onClick={() => navigate(linkPage.PERMIT_DATA)} className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer">
          <FcViewDetails className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Data Izin</span>
        </div>
      </div>

      <div className="content mb-3 tw-relative">
        <h5 className="float-start font-16 font-500 ">Kegiatan</h5>
        <div className="clearfix"></div>
      </div>

      <div className="tw-mx-4 tw-grid tw-grid-cols-4 tw-gap-x-3 tw-gap-y-5 tw-mb-8 tw-relative">
        <div onClick={() => navigate(linkPage.ACTIVITY_FORM)} className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer">
          <FcPlanner className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Form Kegiatan</span>
        </div>
        <div onClick={() => navigate(linkPage.ACTIVITY_DATA)} className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer">
          <FcNews className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Data Kegiatan</span>
        </div>
      </div> */}
    </BaseLayout>
  );
};
