import {
  SettingResetPassword,
  SettingUpdateAccount,
} from "@components/resolvers/setting.resolvers";
import { BaseService } from "./base.service";
import { AccountProfileResponse } from "./dtos/account.dto";
import { BaseResponse } from "./dtos/base.dto";

interface AccountServiceInterface {
  queries: {
    GET_ACCOUNT_PROFILE: string;
  };
  profile(): Promise<AccountProfileResponse>;
  updateTokenFirebase(token: string): Promise<BaseResponse<boolean>>;
  resetPassword(payload: SettingResetPassword): Promise<BaseResponse<boolean>>;
  updateAccount(payload: SettingUpdateAccount): Promise<BaseResponse<boolean>>;
}
export const AccountService: AccountServiceInterface = {
  queries: {
    GET_ACCOUNT_PROFILE: "GET_ACCOUNT_PROFILE",
  },
  profile: async () => {
    try {
      const request = await BaseService.request().get<AccountProfileResponse>(
        "/account/profile"
      );

      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  resetPassword: async (payload) => {
    try {
      const request = await BaseService.request().post<BaseResponse<boolean>>(
        "/account/reset-password",
        { password: payload.password_new }
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  updateAccount: async (payload) => {
    try {
      const request = await BaseService.request().post<BaseResponse<boolean>>(
        "/account/edit-account",
        payload
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  updateTokenFirebase: async (token: string) => {
    try {
      const request = await BaseService.request().post<BaseResponse<boolean>>(
        "/account/update-token-firebase",
        { token }
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
};
