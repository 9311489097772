import { PopupCustom, PopupSuccess } from "@components/generals/popup";
import { SpinerPage } from "@components/loaders/spiner";
import {
  ActivityRequest,
  activityRequestSchema,
} from "@components/resolvers/activity.resolvers";
import { yupResolver } from "@hookform/resolvers/yup";
import { ActivityService } from "@services/activity.service";
import { ActivityForm } from "@services/dtos/activity.dto";
import { FinanceService } from "@services/finance.service";
import { useNotificationStore } from "@stores/notification.store";
import { useThemeStore } from "@stores/theme.store";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toRp } from "@utils/helper";
import { linkPage } from "@utils/router";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaChevronDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const ActivityFormPage = () => {
  const navigate = useNavigate();
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);
  const { pushNotification } = useNotificationStore((state) => state);

  const [activityForm, setActivityForm] = useState<ActivityForm | undefined>();
  const [popupForm, setPopupForm] = useState<boolean>(false);
  const [popupSuccess, setPopupSuccess] = useState<boolean>(false);

  const { register, handleSubmit } = useForm<ActivityRequest>({
    resolver: yupResolver<ActivityRequest>(activityRequestSchema),
  });

  const { data: activities } = useQuery(
    [ActivityService.queries.GET_ACTIVITY_LIST_FORM],
    ActivityService.listForm
  );

  const { data: pockets } = useQuery(
    [FinanceService.queries.GET_FINANCE_POCKET],
    () => FinanceService.pocket()
  );

  const { mutate, isLoading } = useMutation(ActivityService.request, {
    onSuccess: (response) => {
      if (response.success) {
        setPopupSuccess(true)
      } else {
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Form Kegiatan",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Form Kegiatan" });
  }, [setFooter, setHeader, setAppBar]);

  const onChangeActivityForm = (e: ChangeEvent<HTMLSelectElement>) => {
    const data = activities?.data?.find(
      (item) => item.id === parseInt(e.target.value)
    );
    setActivityForm(data);
  };

  const onPayment = (activityRequest: ActivityRequest) => {
    const balancePocket =
      pockets?.data?.find((item) => item.typePocket === activityRequest.paymentMethod)
        ?.balance ?? 0;
    if (activityRequest.cost > balancePocket) {
      pushNotification({
        type: "error",
        message: "Jumlah biaya melebihi saldo kantong yang di pilih!",
      });
      return;
    }
    setPopupForm(false)
    mutate(activityRequest)
  };

  return (
    <>
      <SpinerPage show={isLoading} />
      <div className="card card-style mb-3">
        <div className="content mb-2 !tw-mt-6">
          <div className="input-style has-borders input-style-always-active no-icon">
            <label htmlFor="form5" className="color-highlight font-500">
              Pilih Kegiatan
            </label>
            <select className="bg-theme" onChange={onChangeActivityForm}>
              <option value="default">Pilih Kegiatan</option>
              {activities?.data?.map((item, idx) => (
                <option key={idx} value={item.id}>
                  {item.activity}
                </option>
              ))}
            </select>
            <span>
              <i>
                <FaChevronDown />
              </i>
            </span>
          </div>
        </div>
      </div>
      {activityForm && (
        <div className="card card-style mb-3">
          <div className="content d-flex flex-column mb-0">
            <div className="color-highlight">Nama Kegiatan</div>
            <div className="color-theme mb-3">
              {activityForm.activity ?? "-"}
            </div>
            <div className="color-highlight">Lokasi Kegiatan</div>
            <div className="color-theme mb-3">
              {activityForm.location ?? "-"}
            </div>
            <div className="color-highlight">Tanggal Pelaksanaan</div>
            <div className="color-theme mb-3">
              {moment(activityForm.date ?? Date.now()).format(
                "dddd, DD MMMM YYYY | HH:mm"
              )}
            </div>
            <div className="color-highlight">Kuota</div>
            <div className="color-theme mb-3">
              <span className="badge bg-blue-dark color-white font-10 tw-py-1 px-2 rounded-2 ">
                {activityForm.quota ?? 0} Kursi
              </span>
            </div>
            <div className="color-highlight">Biaya</div>
            <div className="color-theme mb-3">
              {activityForm.optionCost
                ? activityForm.optionCost
                    .split(",")
                    .map((cost) => toRp(parseInt(cost)))
                    .join(" | ")
                : toRp(activityForm.cost)}
            </div>
            <div className="color-highlight">Catatan</div>
            <div className="color-theme mb-3">{activityForm.notes ?? "-"}</div>
            <div className="divider mb-3"></div>
            <button
              onClick={() => setPopupForm(true)}
              className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow mb-3 tw-w-full tw-h-11"
            >
              Pilih Kegiatan
            </button>
          </div>
        </div>
      )}
      <PopupCustom
        show={popupForm}
        position="bottom"
        height={360}
        onCancel={() => setPopupForm(false)}
      >
        <div className="pb-2 px-3">
          <h4 className="font-700 mt-3">Konfirmasi Pembayaran</h4>
          <div className="divider mt-3 mb-4"></div>
          <form autoComplete="off" onSubmit={handleSubmit(onPayment)}>
            <input
              type="hidden"
              value={activityForm?.id ?? 0}
              {...register("activityId", { value: activityForm?.id ?? 0 })}
            />
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input
                type="text"
                className="form-control bg-theme"
                placeholder="Nama Penjemput"
                {...register("pickup")}
              />
              <label
                htmlFor="form1"
                className="color-highlight font-400 font-13"
              >
                Nama Penjemput
              </label>
            </div>
            <div className="input-style has-borders input-style-always-active no-icon mb-4">
              <label htmlFor="form5" className="color-highlight font-500">
                Pilih Kantong Bayar
              </label>
              <select
                defaultValue="UANGSAKU"
                className="bg-theme"
                {...register("paymentMethod", { value: "UANGSAKU" })}
              >
                <option value="default" disabled>
                  Pilih Kantong Bayar
                </option>
                {pockets?.data?.map((item, idx) => (
                  <option
                    key={idx}
                    value={item.typePocket}
                    disabled={item.typePocket !== "UANGSAKU"}
                  >
                    {item.namePocket} ({toRp(item.balance)})
                  </option>
                ))}
              </select>
              <span>
                <i>
                  <FaChevronDown />
                </i>
              </span>
            </div>
            <div className="input-style has-borders input-style-always-active no-icon mb-4">
              <label htmlFor="form5" className="color-highlight font-500">
                Pilih Biaya
              </label>
              <select className="bg-theme" {...register("cost")}>
                <option value="">Pilih Biaya</option>
                {activityForm?.optionCost ? (
                  activityForm.optionCost.split(",").map((cost, idx) => (
                    <option key={idx} value={cost}>
                      {toRp(parseInt(cost))}
                    </option>
                  ))
                ) : (
                  <option value={activityForm?.cost ?? 0}>
                    {toRp(activityForm?.cost ?? 0)}
                  </option>
                )}
              </select>
              <span>
                <i>
                  <FaChevronDown />
                </i>
              </span>
            </div>

            <button
              type="submit"
              className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow mt-4 mb-3 tw-w-full tw-h-11"
            >
              Konfirmasi Bayar
            </button>
          </form>
        </div>
      </PopupCustom>
      <PopupSuccess
        show={popupSuccess}
        title="Berhasil!"
        message="Berhasil mengikuti kegiatan, silahkan lihat detail kegiatan!"
        textButton="Lihat Detail"
        onConfirm={() =>
          navigate(linkPage.ACTIVITY_DATA_DETAIL, { replace: true })
        }
      />
    </>
  );
};
