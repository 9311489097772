import { useThemeStore } from "@stores/theme.store";
import { useEffect } from "react";

export const MarketplaceSettingPage = () => {
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Setting Belanja",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Setting Belanja" });
  }, [setFooter, setHeader, setAppBar]);

  return (
    <div className="card card-style">
      <div className="content mt-4">
        <div className="input-style input-style-always-active has-borders  validate-field mb-4">
          <input
            type="text"
            className="form-control validate-name"
            id="limi"
            placeholder="Rp 0"
          />
          <label
            htmlFor="limi"
            className="color-highlight text-uppercase font-700 font-10"
          >
            Limit Belanja
          </label>
        </div>
        <div className="tw-leading-tight tw-text-xs tw-italic">
          Limit Ini Akan membatasi Santri untuk Berbelanja di Al-barokah{" "}
          <b>Via Admin di Pondok</b>. Adapun Belanja Oleh Orangtua Tidak
          Dibatasi
        </div>
        <button className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow mt-4 tw-w-full tw-h-11">
          Simpan
        </button>
      </div>
    </div>
  );
};
