import { PopupSuccess } from "@components/generals/popup";
import { SpinerPage } from "@components/loaders/spiner";
import { PermitRequest, permitRequestSchema } from "@components/resolvers/permit.resolvers";
import { yupResolver } from "@hookform/resolvers/yup";
import { PermitService } from "@services/permit.service";
import { useNotificationStore } from "@stores/notification.store";
import { useThemeStore } from "@stores/theme.store";
import { useMutation } from "@tanstack/react-query";
import id from "date-fns/locale/id";
import Cookies from "js-cookie";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { FaChevronDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
registerLocale("id", id);
setDefaultLocale("id");

export const PermitFormPage = () => {
  const navigate = useNavigate();
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);
  const { pushNotification } = useNotificationStore((state) => state);

  const [popupSuccess, setPopupSuccess] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [listOpenDate, setListOpenDate] = useState<Date[]>([]);
  const [timeMode, setTimeMode] = useState<string>("");

  const { register, handleSubmit, setValue } = useForm<PermitRequest>({
    resolver: yupResolver<PermitRequest>(permitRequestSchema),
  });

  const { mutate, isLoading } = useMutation(PermitService.request, {
    onSuccess: (response) => {
      if (response.success) {
        setPopupSuccess(true);
      } else {
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Request Izin",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Request Izin" });
  }, [setFooter, setHeader, setAppBar]);

  useEffect(() => {
    const currentDate = moment().clone();
    const endDate = moment().add(3, "months").toDate();
    const dateList = [];

    while (currentDate.isSameOrBefore(endDate)) {
      switch (timeMode) {
        case "KUNJUNGAN (Senin - Sabtu : 16.00 - 17.00)":
          if (currentDate.day() === 0) dateList.push(currentDate.toDate());
          break;
        case "KUNJUNGAN (Ahad : 07.00 - 17.00)":
          if (currentDate.day() !== 0) dateList.push(currentDate.toDate());
          break;
        case "KELUAR (Sabtu : 16.00 - 17.00)":
          if (currentDate.day() !== 6 || (Cookies.get("gender") === "Pria" ? currentDate.week() % 2 !== 1 : currentDate.week() % 2 !== 0)) dateList.push(currentDate.toDate());
          break;
        case "KELUAR (Ahad : 07.00 - 17.00)":
          if (currentDate.day() !== 0 || (Cookies.get("gender") === "Pria" ? currentDate.week() % 2 !== 1 : currentDate.week() % 2 !== 0)) dateList.push(currentDate.toDate());
          break;
        case "MENGINAP (Ahad)":
          if (currentDate.day() !== 0 || (Cookies.get("gender") === "Pria" ? currentDate.week() % 2 !== 1 : currentDate.week() % 2 !== 0)) dateList.push(currentDate.toDate());
          break;
      }
      currentDate.add(1, "days");
    }
    setListOpenDate(dateList);
  }, [timeMode]);

  const onChangeMode = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const mode = e.target.value;
    const time = e.target.options[e.target.selectedIndex]?.dataset?.time ?? "";
    setValue("time", time);
    setTimeMode(`${mode} (${time})`);
  };

  const onChangeDate = (date: Date | null) => {
    if (date !== null) {
      setValue("dateBack", moment(date).format("YYYY-MM-DD HH:mm:ss"));
      setValue("datePermit", moment(date).format("YYYY-MM-DD HH:mm:ss"));
      setStartDate(date);
    }
  };

  return (
    <>
      <SpinerPage show={isLoading} />
      <div className="card card-style">
        <div className="content mb-4 !tw-mt-6">
          <form autoComplete="off" onSubmit={handleSubmit((data) => mutate(data))}>
            <div className="input-style has-borders input-style-always-active no-icon mb-3">
              <label htmlFor="form5" className="color-highlight font-500">
                Pilih Izin
              </label>
              <select className="bg-theme" {...register("mode")} onChange={onChangeMode}>
                <option value="">Pilih Izin</option>
                <option value="KUNJUNGAN" data-time="Senin - Sabtu : 16.00 - 17.00">
                  Kunjungan (Senin - Sabtu : 16.00 - 17.00)
                </option>
                <option value="KUNJUNGAN" data-time="Ahad : 07.00 - 17.00">
                  Kunjungan (Ahad : 07.00 - 17.00)
                </option>
                <option value="KELUAR" data-time="Sabtu : 16.00 - 17.00">
                  Keluar (Sabtu : 16.00 - 17.00)
                </option>
                <option value="KELUAR" data-time="Ahad : 07.00 - 17.00">
                  Keluar (Ahad : 07.00 - 17.00)
                </option>
                <option value="MENGINAP" data-time="Ahad">
                  Menginap (Ahad)
                </option>
              </select>
              <input type="hidden" {...register("time")} />
              <span>
                <i>
                  <FaChevronDown />
                </i>
              </span>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4 mt-3">
              <DatePicker
                selected={startDate}
                minDate={new Date()}
                maxDate={moment().add(3, "months").toDate()}
                onChange={onChangeDate}
                className="form-control dark:form-control"
                dateFormat="dd MMMM yyyy"
                excludeDates={listOpenDate}
                calendarStartDay={0}
              />
              <span>
                <i>
                  <FaChevronDown />
                </i>
              </span>
              <label htmlFor="permit-date" className="color-highlight font-400 font-13">
                Tanggal Izin
              </label>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input type="text" className="form-control bg-theme" placeholder="Nama Penjemput" {...register("pickup")} />
              <label htmlFor="form1" className="color-highlight font-400 font-13">
                Nama Penjemput
              </label>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <input type="text" className="form-control bg-theme" placeholder="Status Penjemput" {...register("pickupStatus")} />
              <label htmlFor="form1" className="color-highlight font-400 font-13">
                Status Penjemput
              </label>
            </div>
            <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
              <textarea className="form-control bg-theme" placeholder="Catatan ..." {...register("notes")} />
              <label htmlFor="form1" className="color-highlight font-400 font-13">
                Catatan
              </label>
            </div>
            <button className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-green-dark tw-grow mt-4 tw-w-full tw-h-11">Request Izin</button>
          </form>
        </div>
      </div>
      <PopupSuccess show={popupSuccess} title="Berhasil!" message="Berhasil membuat permintaan izin" textButton="Kembali" onConfirm={() => navigate(-1)} />
    </>
  );
};
