import AvatarImage from "@assets/images/avatar.png";
import { PopupConfirm } from "@components/generals/popup";
import { AccountService } from "@services/account.service";
import { useBiometricStore } from "@stores/biometric.store";
import { useSchoolStore } from "@stores/school.store";
import { useThemeStore } from "@stores/theme.store";
import { useQuery } from "@tanstack/react-query";
import { androidSetupBiometric } from "@utils/android";
import { linkPage } from "@utils/router";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import {
  FaAngleRight,
  FaEdit,
  FaFingerprint,
  FaLock,
  FaSignOutAlt,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const ProfilePage = () => {
  const navigate = useNavigate();
  const [popupLogout, setPopupLogout] = useState<boolean>(false);
  const [isBiometric, setIsBiometric] = useState<boolean>(false);
  const { isActiveBiometric } = useBiometricStore((state) => state);
  const { setFooter, setHeader, setAppBar, setBottomNavigation } =
    useThemeStore((state) => state);
  const { clearInfo } = useSchoolStore((state) => state);

  const { data: accountProfile } = useQuery(
    [AccountService.queries.GET_ACCOUNT_PROFILE],
    AccountService.profile
  );

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Profile",
      buttonBack: false,
      avatar: true,
    });
    setAppBar({ title: "Profile" });
  }, [setFooter, setHeader, setAppBar]);

  useEffect(() => {
    const skip = localStorage.getItem("is-biometric");
    setIsBiometric(skip === "true");
  }, []);

  const onSetupBiometric = () => {
    const status = localStorage.getItem("status-biometric");
    if (status === "register") {
      androidSetupBiometric("remove");
    } else {
      androidSetupBiometric("register");
    }
  };

  const onLogout = () => {
    Cookies.remove("token");
    Cookies.remove("redirect");
    Cookies.remove("prefix");
    if (!localStorage.getItem("prefix")) clearInfo();
    let prefix = localStorage.getItem("school-prefix");
    if (prefix) {
      navigate(`/auth/login?prefix=${prefix}`);
    } else {
      navigate(`/auth/login`);
    }
    setPopupLogout(false);
    setBottomNavigation("hidden");
    setAppBar({ show: false });
  };

  return (
    <>
      <div className="card card-style">
        <div className="content mb-0">
          <div className="d-flex">
            <div>
              <img
                src={AvatarImage}
                width="50"
                alt="avatar"
                className="me-3 bg-highlight rounded-xl"
              />
            </div>
            <div>
              <h1 className="mb-0 pt-1">
                {accountProfile?.data?.biodata.nama ?? "-"}
              </h1>
              <p className="color-highlight font-11 mt-n2">
                {accountProfile?.data?.biodata.kelas ?? "-"}
              </p>
            </div>
          </div>
          <hr className="tw-mb-7" />

          <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
            <input
              type="text"
              disabled={true}
              className="form-control bg-theme"
              placeholder={accountProfile?.data?.account.nis ?? "-"}
            />
            <label htmlFor="form1" className="color-highlight font-400 font-13">
              Nis
            </label>
          </div>

          <div className="input-style has-borders no-icon input-style-always-active validate-field mb-4">
            <input
              type="text"
              disabled={true}
              className="form-control bg-theme"
              placeholder={accountProfile?.data?.account.email ?? "-"}
            />
            <label htmlFor="form2" className="color-highlight font-400 font-13">
              Email
            </label>
          </div>

          <div className="input-style has-borders no-icon input-style-always-active validate-field mb-4">
            <input
              type="text"
              disabled={true}
              className="form-control bg-theme"
              placeholder={accountProfile?.data?.account.nohp ?? "-"}
            />
            <label htmlFor="form3" className="color-highlight font-400 font-13">
              Nomor Handphone
            </label>
          </div>
        </div>
      </div>

      <div className="card card-style">
        <div className="content mt-0 mb-0">
          <div className="list-group list-group-flush">
            <div
              className="list-group-item tw-bg-inherit d-flex tw-px-0 tw-py-3 tw-border-[rgba(0,0,0,0.05)] dark:tw-border-[rgba(255,255,255,0.05)] tw-cursor-pointer"
              onClick={() =>
                navigate(linkPage.SETTING_UPDATE_ACCOUNT, {
                  state: accountProfile?.data?.account,
                })
              }
            >
              <div className="rounded-sm tw-w-8 tw-h-8 d-flex tw-flex-none my-auto bg-blue-dark">
                <FaEdit className="m-auto" />
              </div>
              <div className="tw-grow mx-3 my-auto d-flex flex-column tw-w-[calc(100vw-140px)]">
                <span className="tw-font-medium tw-text-sm dark:tw-text-white tw-truncate">
                  Ubah data akun
                </span>
                <strong className="tw-font-medium tw-text-[11px] tw-text-[#adb5bd] tw-truncate">
                  Merubah informasi akun
                </strong>
              </div>
              <div className="my-auto">
                <FaAngleRight
                  className="dark:tw-text-white tw-text-gray-500"
                  size={10}
                />
              </div>
            </div>
            <div
              className="list-group-item tw-bg-inherit d-flex tw-px-0 tw-py-3 tw-border-[rgba(0,0,0,0.05)] dark:tw-border-[rgba(255,255,255,0.05)] tw-cursor-pointer"
              onClick={() => navigate(linkPage.SETTING_RESET_PASSWORD)}
            >
              <div className="rounded-sm tw-w-8 tw-h-8 d-flex tw-flex-none my-auto bg-aqua-dark">
                <FaLock className="m-auto" />
              </div>
              <div className="tw-grow mx-3 my-auto d-flex flex-column tw-w-[calc(100vw-140px)]">
                <span className="tw-font-medium tw-text-sm dark:tw-text-white tw-truncate">
                  Reset password
                </span>
                <strong className="tw-font-medium tw-text-[11px] tw-text-[#adb5bd] tw-truncate">
                  Mereset ulang password
                </strong>
              </div>
              <div className="my-auto">
                <FaAngleRight
                  className="dark:tw-text-white tw-text-gray-500"
                  size={10}
                />
              </div>
            </div>
            {isBiometric ? (
              <div
                className="list-group-item tw-bg-inherit d-flex tw-px-0 tw-py-3 tw-border-[rgba(0,0,0,0.05)] dark:tw-border-[rgba(255,255,255,0.05)] tw-cursor-pointer"
                onClick={onSetupBiometric}
              >
                <div
                  className={`rounded-sm tw-w-8 tw-h-8 d-flex tw-flex-none my-auto ${
                    isActiveBiometric ? "bg-teal-dark" : "bg-gray-dark"
                  }`}
                >
                  <FaFingerprint className="m-auto" />
                </div>
                <div className="tw-grow mx-3 my-auto d-flex flex-column tw-w-[calc(100vw-140px)]">
                  <span className="tw-font-medium tw-text-sm dark:tw-text-white tw-truncate">
                    {isActiveBiometric
                      ? "Login Biometrik Aktif"
                      : "Aktifkan Login Biometrik"}
                  </span>
                  <strong className="tw-font-medium tw-text-[11px] tw-text-[#adb5bd] tw-truncate">
                    {isActiveBiometric
                      ? "Login cepat biometrik sudah aktif"
                      : "Fitur login cepat dengan biometrik"}
                  </strong>
                </div>
                <div className="my-auto">
                  <FaAngleRight
                    className="dark:tw-text-white tw-text-gray-500"
                    size={10}
                  />
                </div>
              </div>
            ) : null}
            <div
              className="list-group-item tw-bg-inherit d-flex tw-px-0 tw-py-3 tw-border-[rgba(0,0,0,0.05)] dark:tw-border-[rgba(255,255,255,0.05)] tw-cursor-pointer"
              onClick={() => setPopupLogout(!popupLogout)}
            >
              <div className="rounded-sm tw-w-8 tw-h-8 d-flex tw-flex-none my-auto bg-red-dark">
                <FaSignOutAlt className="m-auto" />
              </div>
              <div className="tw-grow mx-3 my-auto d-flex flex-column tw-w-[calc(100vw-140px)]">
                <span className="tw-font-medium tw-text-sm dark:tw-text-white tw-truncate">
                  Keluar
                </span>
                <strong className="tw-font-medium tw-text-[11px] tw-text-[#adb5bd] tw-truncate">
                  Keluar dari akun ini
                </strong>
              </div>
              <div className="my-auto">
                <FaAngleRight
                  className="dark:tw-text-white tw-text-gray-500"
                  size={10}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopupConfirm
        show={popupLogout}
        title="Keluar dari akun?"
        message="Pastikan sebelum keluar dari akun ini, simpanlah username dan password akun agar tidak ada kendala untuk login selanjutnya."
        textCancel="Kembali"
        textConfirm="Ya, Keluar"
        onConfirm={onLogout}
        onCancel={() => setPopupLogout(false)}
      />
    </>
  );
};
