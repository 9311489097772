import { Spiner } from "@components/loaders/spiner";
import {
  AuthForgotPassword,
  authForgotPasswordSchema,
} from "@components/resolvers/auth.resolvers";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthService } from "@services/auth.service";
import { useNotificationStore } from "@stores/notification.store";
import { useMutation } from "@tanstack/react-query";
import { linkPage } from "@utils/router";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaAt, FaCheck, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const { pushNotification } = useNotificationStore((state) => state);
  const { register, handleSubmit } = useForm<AuthForgotPassword>({
    resolver: yupResolver<AuthForgotPassword>(authForgotPasswordSchema),
  });

  useEffect(() => {
    initValidate();
  }, []);

  const { mutate, isLoading } = useMutation(AuthService.forgotPassword, {
    onSuccess: (response) => {
      if (response.success) {
        navigate(linkPage.AUTH_LOGIN);
      } else {
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  return (
    <div className="card card-style">
      <div className="content mt-2 mb-0">
        <h4>Pulihkan akunmu</h4>
        <p>
          Cukup masukkan email yang sudah terdaftar di akun dan kami akan
          mengirimkan petunjuk pengaturan ulang kata sandi
        </p>

        <form
          autoComplete="off"
          method="post"
          onSubmit={handleSubmit((data) => mutate(data))}
        >
          <div className="input-style no-borders has-icon validate-field mb-4">
            <i className="lh-1">
              <FaAt />
            </i>
            <input
              type="email"
              className="form-control validate-password"
              placeholder="Email"
              {...register('email')}
            />
            <label htmlFor="form3a" className="color-blue-dark font-10 mt-1">
              Email
            </label>
            <i className="disabled invalid color-red-dark">
              <FaTimes />
            </i>
            <i className="disabled valid color-green-dark">
              <FaCheck />
            </i>
            <em>(*wajib)</em>
          </div>

          <button
            type="submit"
            className="btn btn-m mt-4 mb-3 btn-full tw-w-full bg-green-dark rounded-sm text-uppercase font-900"
          >
            {isLoading ? <Spiner /> : "Kirim Email"}
          </button>
        </form>

        <div className="text-center">
          <Link to={linkPage.AUTH_LOGIN}>Kembali Login</Link>
        </div>

        <div className="divider mt-4 mb-3"></div>

        <p className="text-center tw-text-sm tw-font-light tw-text-gray-500 mb-4">
          Silahkan hubungi{" "}
          <span className="tw-font-medium tw-text-primary-700">Admin</span> jika
          terjadi masalah <br /> terhadap aplikasi
        </p>
      </div>
    </div>
  );
};
