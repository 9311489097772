import { BaseService } from "./base.service";
import {
  InfoResponse,
  StudentAbsencesResponse,
  StudentAchievementResponse,
  StudentBioResponse,
  StudentHealthResponse,
  StudentTahfidzResponse,
  StudentViolationsResponse,
} from "./dtos/school.dto";

interface SchoolServiceInterface {
  queries: {
    GET_SCHOOL_INFO: string;
    GET_SCHOOL_STUDENT_BIO: string;
    GET_SCHOOL_STUDENT_HEALTH: string;
    GET_SCHOOL_STUDENT_VIOLATIONS: string;
    GET_SCHOOL_STUDENT_ACHIEVEMENT: string;
    GET_SCHOOL_STUDENT_ABSENCES: string;
    GET_SCHOOL_STUDENT_TAHFIDZ: string;
  };
  info(prefix: string): Promise<InfoResponse>;
  studentBio(): Promise<StudentBioResponse>;
  studentHealth(limit: string): Promise<StudentHealthResponse>;
  studentTahfidz(limit: string): Promise<StudentTahfidzResponse>;
  studentViolations(limit: string): Promise<StudentViolationsResponse>;
  studentAchievement(limit: string): Promise<StudentAchievementResponse>;
  studentAbsences(limit: string): Promise<StudentAbsencesResponse>;
}
export const SchoolService: SchoolServiceInterface = {
  queries: {
    GET_SCHOOL_INFO: "GET_SCHOOL_INFO",
    GET_SCHOOL_STUDENT_BIO: "GET_SCHOOL_STUDENT_BIO",
    GET_SCHOOL_STUDENT_HEALTH: "GET_SCHOOL_STUDENT_HEALTH",
    GET_SCHOOL_STUDENT_VIOLATIONS: "GET_SCHOOL_STUDENT_VIOLATIONS",
    GET_SCHOOL_STUDENT_ACHIEVEMENT: "GET_SCHOOL_STUDENT_ACHIEVEMENT",
    GET_SCHOOL_STUDENT_ABSENCES: "GET_SCHOOL_STUDENT_ABSENCES",
    GET_SCHOOL_STUDENT_TAHFIDZ: "GET_SCHOOL_STUDENT_TAHFIDZ",
  },
  info: async (prefix: string) => {
    try {
      const request = await BaseService.request().get<InfoResponse>(
        `/school/info/${prefix}`
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  studentBio: async () => {
    try {
      const request = await BaseService.request().get<StudentBioResponse>(
        "/school/student-bio"
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  studentHealth: async (limit: string) => {
    try {
      const request = await BaseService.request().get<StudentHealthResponse>(
        "/school/student-health",
        { params: { limit } }
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  studentTahfidz: async (limit: string) => {
    try {
      const request = await BaseService.request().get<StudentTahfidzResponse>(
        "/school/tahfidz",
        { params: { limit } }
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  studentViolations: async (limit: string) => {
    try {
      const request =
        await BaseService.request().get<StudentViolationsResponse>(
          "/school/student-violations",
          { params: { limit } }
        );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  studentAchievement: async (limit: string) => {
    try {
      const request =
        await BaseService.request().get<StudentAchievementResponse>(
          "/school/student-achievement",
          { params: { limit } }
        );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  studentAbsences: async (limit: string) => {
    try {
      const request = await BaseService.request().get<StudentAbsencesResponse>(
        "/school/student-absences",
        { params: { limit } }
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
};
