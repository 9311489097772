import { Spiner, SpinerPage } from "@components/loaders/spiner";
import { HomeService } from "@services/home.service";
import { SchoolService } from "@services/school.service";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { useSchoolStore } from "@stores/school.store";
import { useThemeStore } from "@stores/theme.store";
import { useQuery } from "@tanstack/react-query";
import {
  androidChangeThemeStatusBar,
  androidIsAvailableBiomatric,
  androidUnreadInbox,
} from "@utils/android";
import { toRp } from "@utils/helper";
import { linkPage } from "@utils/router";
import { useEffect, useState } from "react";
import {
  FcBusinessContact,
  FcGraduationCap,
  FcHighPriority,
  FcInvite,
  FcMoneyTransfer,
  FcPackage,
  FcSurvey,
  FcWorkflow,
} from "react-icons/fc";
import { HiCurrencyDollar, HiNewspaper } from "react-icons/hi";
import { MdArrowForwardIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Biodata } from "@assets/images/vector/profileuser.svg";
import { ReactComponent as Absensi } from "@assets/images/vector/deadlinestopwatch.svg";
import { ReactComponent as Prestasi } from "@assets/images/vector/medalreward.svg";
import { ReactComponent as Kantong } from "@assets/images/vector/wallet.svg";
import { ReactComponent as Lainnya } from "@assets/images/vector/more.svg";
import { ReactComponent as Kesehatan } from "@assets/images/vector/medicalhistory.svg";
import { ReactComponent as Pelanggaran } from "@assets/images/vector/warning.svg";
import { ReactComponent as Bill } from "@assets/images/vector/bill-.svg";
import { ReactComponent as Tabungan } from "@assets/images/vector/moneybag.svg";
import AvatarImage from "@assets/images/avatar-.png";
import { FinanceService } from "@services/finance.service";

export const DashboardPage = () => {
  const navigate = useNavigate();
  const [loadingOpenSlider, setLoadingOpenSlider] = useState<boolean>(false);
  const { themeMode, setAppBar, setFooter, setHeader } = useThemeStore(
    (state) => state
  );
  const { name } = useSchoolStore((state) => state);

  const { data: dataUangSaku, isLoading: isLoadingUangSaku } = useQuery(
    [HomeService.queries.GET_BILLS_AND_BALANCES],
    HomeService.billsAndBalances
  );
  const { data: pockets, isLoading: isLoadingPockets } = useQuery(
    [FinanceService.queries.GET_FINANCE_POCKET],
    () => FinanceService.pocket()
  );
  const { data: dataSliders, isLoading: isLoadingSliders } = useQuery(
    [HomeService.queries.GET_SLIDER],
    HomeService.sliders
  );
  const { data: dataSiswa } = useQuery(
    [SchoolService.queries.GET_SCHOOL_STUDENT_BIO],
    SchoolService.studentBio
  );

  useEffect(() => {
    if (themeMode === "light") {
      androidChangeThemeStatusBar("#1999FA", "dark");
    }

    if (themeMode === "dark") {
      androidChangeThemeStatusBar("#114C7D", "light");
    }

    const setupUnreadInbox = setTimeout(() => androidUnreadInbox(), 1000);
    const setupBiometric = setTimeout(() => {
      localStorage.setItem("is-biometric", "false");
      androidIsAvailableBiomatric();
    }, 1000);

    return () => {
      clearTimeout(setupBiometric);
      clearTimeout(setupUnreadInbox);
    };
  }, [themeMode]);

  useEffect(() => {
    setFooter(true);
    setHeader({
      type: "small",
      buttonBack: false,
      avatar: true,
      title: <>{"SIPond"}</>,
    });
    setAppBar({ title: "Home" });
  }, [setAppBar, setFooter, setHeader, name]);

  const onClickSlider = (
    type: "IN_ROUTE" | "IN_APP" | "OUT_APP",
    link: string
  ) => {
    switch (type) {
      case "IN_ROUTE":
        navigate(link);
        break;
      case "IN_APP":
        document.location.href = link;
        break;
      case "OUT_APP":
        setLoadingOpenSlider(true);
        setTimeout(() => {
          setLoadingOpenSlider(false);
        }, 3000);
        document.location.href = link;
        break;
    }
  };

  return (
    <>
      <SpinerPage show={loadingOpenSlider} />

      <div className="card card-style">
        <div className="content m-0">
          {/* <div className="d-flex tw-flex-col tw-px-6 tw-py-4">
            <span className="tw-font-medium tw-text-base tw-text-gray-500 dark:tw-text-white">
              Data Siswa
            </span>
            <div className="tw-flex tw-text-gray-500 dark:tw-text-white">
              <div className="tw-flex-grow">
                <div>Nama Santri</div>
                <div>Kelas</div>
              </div>
              <div className="tw-flex-grow">
                <div>{dataSiswa?.data?.nama}</div>
                <div>{dataSiswa?.data?.kelas}</div>
              </div>
            </div>
          </div> */}
          <div className="d-flex justify-content-between align-items-center tw-px-6 tw-py-4">
            <div>
              <h1 className="mb-0 pt-1">{dataSiswa?.data?.nama ?? "-"}</h1>
              <p
                className="color-highlight font-11 fw-bold mt-n2 mb-1"
                style={{ letterSpacing: "2px" }}
              >
                {dataSiswa?.data?.kelas ?? "-"}
              </p>
              <p
                className="color-highlight font-14 fw-bold mt-n2"
                style={{ letterSpacing: "1px" }}
              >
                {name ?? "Sistem Informasi Pondok"}
              </p>
            </div>
            <div>
              <img
                src={AvatarImage}
                width="50"
                alt="avatar"
                className="bg-highlight rounded-xl"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card preload-img bg-header-footer-default">
        <div className="card-body">
          <div className="card card-style ms-0 me-0 mb-0 bg-theme">
            <div className="d-flex py-2 px-3">
              <div
                className="flex-fill d-flex py-1 ps-2 pe-1"
                onClick={() => navigate(linkPage.FINANCE_POCKET)}
              >
                {isLoadingUangSaku ? (
                  <div className="tw-py-2 m-auto">
                    <Spiner className="!tw-text-gray-500 dark:!tw-text-gray-500 tw-w-6 tw-h-6" />
                  </div>
                ) : (
                  <>
                    {/* <Uang
                      className="float-start me-3 color-yellow-dark my-auto"
                      width={40}
                      height={40}
                    /> */}
                    <div className="d-flex flex-column w-100 my-auto">
                      <span
                        className="lh-1 fw-light mb-2"
                        style={{ fontSize: 13 }}
                      >
                        Saldo
                      </span>
                      <span
                        className="lh-sm fw-semibold"
                        style={{ fontSize: 16 }}
                      >
                        {toRp(dataUangSaku?.data?.saldo ?? 0)}
                      </span>
                    </div>
                    <MdArrowForwardIos size={40} className="my-auto me-0" />
                  </>
                )}
              </div>
              {/* <div className="flex-fill d-flex ps-2" onClick={() => navigate(linkPage.FINANCE_BILL)}>
                {isLoadingUangSaku ? (
                  <div className="tw-py-2 m-auto">
                    <Spiner className="!tw-text-gray-500 dark:!tw-text-gray-500 tw-w-6 tw-h-6" />
                  </div>
                ) : (
                  <>
                    <HiNewspaper className="float-start me-2 color-blue-dark my-auto" size={40} />
                    <div className="d-flex flex-column w-100 my-auto">
                      <span className="lh-1 fw-light" style={{ fontSize: 12 }}>
                        Tagihan
                      </span>
                      <span className="lh-sm fw-semibold" style={{ fontSize: 15 }}>
                        {toRp(dataUangSaku?.data?.tagihan ?? 0)}
                      </span>
                    </div>
                  </>
                )}
              </div> */}
            </div>
          </div>
        </div>
        <div className="card-overlay bg-highlight opacity-90"></div>
        <div className="card-overlay dark-mode-tint"></div>
      </div>

      <div className="content mb-0 d-flex justify-content-between">
        <h5
          className="font-16 font-500 mb-0"
          style={{ letterSpacing: "0.5px" }}
        >
          Fitur Pilihan
        </h5>
        <small
          className="color-highlight"
          onClick={() => navigate(linkPage.HOME_OTHER_MENUS)}
        >
          Selengkapnya
        </small>
        {/* <div className="clearfix"></div> */}
      </div>

      <div
        className="tw-mx-0 tw-grid tw-grid-cols-4 tw-gap-x-3 tw-gap-y-5 tw-mb-8"
        style={{
          display: "list-item",
          listStyle: "none",
          whiteSpace: "nowrap",
          overflowX: "scroll",
        }}
      >
        {/* <div onClick={() => navigate(linkPage.MARKETPLACE_PRODUCT)} className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2">
          <FcShop className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Al-Barokah</span>
        </div> */}
        {/* <div onClick={() => navigate(linkPage.FINANCE_VIRTUAL_ACCOUNT_REQUEST)} className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2">
          <FcMoneyTransfer className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Request VA</span>
        </div> */}
        {/* <div onClick={() => navigate(linkPage.ACTIVITY_FORM)} className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2 tw-cursor-pointer">
          <FcPlanner className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Form Kegiatan</span>
        </div> */}
        {/* <div onClick={() => navigate(linkPage.PERMIT_FORM)} className="bg-theme rounded-m tw-shadow text-center tw-flex tw-flex-col text-center tw-py-4 tw-px-2">
          <FcOvertime className="mx-auto tw-mb-2" size={38} />
          <span className="tw-text-xs lh-1">Request Izin</span>
        </div> */}
        <div
          onClick={() => navigate(linkPage.SCHOOL_STUDENT_BIO)}
          className="text-center tw-inline-flex align-items-center tw-flex-col tw-py-4 tw-px-0"
          style={{ width: "22%" }}
        >
          <div
            className="tw-flex tw-flex-col text-center"
            style={{ width: "75px" }}
          >
            <Biodata
              className="mx-auto tw-mb-2 bg-theme rounded-m tw-shadow tw-py-2 tw-px-2"
              width={38}
              height={38}
              style={{ boxSizing: "content-box" }}
            />
            <span className="tw-text-xs lh-1">Biodata</span>
          </div>
        </div>
        <div
          onClick={() => navigate(linkPage.SCHOOL_STUDENT_ABSENCES)}
          className="text-center tw-inline-flex align-items-center tw-flex-col tw-py-4 tw-px-0"
          style={{ width: "22%" }}
        >
          <div
            className="tw-flex tw-flex-col text-center"
            style={{ width: "75px" }}
          >
            <Absensi
              className="mx-auto tw-mb-2 bg-theme rounded-m tw-shadow tw-py-2 tw-px-2"
              width={38}
              height={38}
              style={{ boxSizing: "content-box" }}
            />
            <span className="tw-text-xs lh-1">Absensi</span>
          </div>
        </div>
        <div
          onClick={() => navigate(linkPage.SCHOOL_STUDENT_HEALTH)}
          className="text-center tw-inline-flex align-items-center tw-flex-col tw-py-4 tw-px-0 tw-cursor-pointer"
          style={{ width: "22%" }}
        >
          <div
            className="tw-flex tw-flex-col text-center"
            style={{ width: "75px" }}
          >
            <Kesehatan
              className="mx-auto tw-mb-2 bg-theme rounded-m tw-shadow tw-py-2 tw-px-2"
              width={38}
              height={38}
              style={{ boxSizing: "content-box" }}
            />
            <span className="tw-text-xs lh-1">Kesehatan</span>
          </div>
        </div>
        <div
          onClick={() => navigate(linkPage.SCHOOL_STUDENT_VIOLATIONS)}
          className="text-center tw-inline-flex align-items-center tw-flex-col tw-py-4 tw-px-0 tw-cursor-pointer"
          style={{ width: "22%" }}
        >
          <div
            className="tw-flex tw-flex-col text-center"
            style={{ width: "75px" }}
          >
            <Pelanggaran
              className="mx-auto tw-mb-2 bg-theme rounded-m tw-shadow tw-py-2 tw-px-2"
              width={38}
              height={38}
              style={{ boxSizing: "content-box" }}
            />
            <span className="tw-text-xs lh-1">Pelanggaran</span>
          </div>
        </div>
        <div
          onClick={() => navigate(linkPage.SCHOOL_STUDENT_ACHIEVEMENT)}
          className="text-center tw-inline-flex align-items-center tw-flex-col tw-py-4 tw-px-0"
          style={{ width: "22%" }}
        >
          <div
            className="tw-flex tw-flex-col text-center"
            style={{ width: "75px" }}
          >
            <Prestasi
              className="mx-auto tw-mb-2 bg-theme rounded-m tw-shadow tw-py-2 tw-px-2"
              width={38}
              height={38}
              style={{ boxSizing: "content-box" }}
            />
            <span className="tw-text-xs lh-1">Prestasi</span>
          </div>
        </div>

        {/* <div
          onClick={() => navigate(linkPage.FINANCE_BILL)}
          className="text-center tw-inline-flex align-items-center tw-flex-col tw-py-4 tw-px-0 tw-cursor-pointer"
          style={{ width: "22%" }}
        >
          <div
            className="tw-flex tw-flex-col text-center"
            style={{ width: "75px" }}
          >
            <Tagihan className="mx-auto tw-mb-2" width={38} height={38} />
            <span className="tw-text-xs lh-1">Tagihan</span>
          </div>
        </div> */}
        <div
          onClick={() => navigate(linkPage.FINANCE_POCKET)}
          className="text-center tw-inline-flex align-items-center tw-flex-col tw-py-4 tw-px-0 tw-cursor-pointer"
          style={{ width: "22%" }}
        >
          <div
            className="tw-flex tw-flex-col text-center"
            style={{ width: "75px" }}
          >
            <Kantong
              className="mx-auto tw-mb-2 bg-theme rounded-m tw-shadow tw-py-2 tw-px-2"
              width={38}
              height={38}
              style={{ boxSizing: "content-box" }}
            />
            <span className="tw-text-xs lh-1">Kantong</span>
          </div>
        </div>
        <div
          onClick={() => navigate(linkPage.HOME_OTHER_MENUS)}
          className="text-center tw-inline-flex align-items-center tw-flex-col tw-py-4 tw-px-0 tw-cursor-pointer"
          style={{ width: "22%" }}
        >
          <div
            className="tw-flex tw-flex-col text-center"
            style={{ width: "75px" }}
          >
            <Lainnya
              className="mx-auto tw-mb-2 bg-theme rounded-m tw-shadow tw-py-2 tw-px-2"
              width={38}
              height={38}
              style={{ boxSizing: "content-box" }}
            />
            <span className="tw-text-xs lh-1">Lainnya</span>
          </div>
        </div>
      </div>

      <div className="content mb-3">
        <h5
          className="font-16 font-500 mb-0"
          style={{ letterSpacing: "0.5px" }}
        >
          Informasi Lainnya
        </h5>
      </div>
      <div className="card card-style">
        <div className="content m-0">
          <div className="d-flex">
            <div className="flex-fill d-flex text-center">
              {isLoadingUangSaku ? (
                <div className="tw-py-2 m-auto">
                  <Spiner className="!tw-text-gray-500 dark:!tw-text-gray-500 tw-w-6 tw-h-6" />
                </div>
              ) : (
                <>
                  <div
                    className="d-flex flex-column w-100 my-auto tw-py-4"
                    onClick={() => navigate(linkPage.FINANCE_POCKET)}
                    style={{ borderRight: "1px solid lightgray" }}
                  >
                    <span className="lh-1 fw-light" style={{ fontSize: 13 }}>
                      <Tabungan
                        className="me-2 color-blue-dark my-auto"
                        width={25}
                        height={25}
                      />
                      Tabungan
                    </span>
                    <span
                      className="lh-sm fw-semibold mt-1"
                      style={{ fontSize: 16 }}
                    >
                      {toRp(
                        pockets?.data?.find(
                          (item) => item.typePocket === "TABUNGAN"
                        )?.balance ?? 0
                      )}
                    </span>
                  </div>
                </>
              )}

              {isLoadingUangSaku ? (
                <div className="tw-py-2 m-auto">
                  <Spiner className="!tw-text-gray-500 dark:!tw-text-gray-500 tw-w-6 tw-h-6" />
                </div>
              ) : (
                <>
                  <div
                    className="d-flex flex-column w-100 my-auto tw-py-4"
                    onClick={() => navigate(linkPage.FINANCE_BILL)}
                  >
                    <span className="lh-1 fw-light" style={{ fontSize: 13 }}>
                      <Bill
                        className="me-2 color-blue-dark my-auto"
                        width={25}
                        height={25}
                      />
                      Kewajiban
                    </span>
                    <span
                      className="lh-sm fw-semibold mt-1"
                      style={{ fontSize: 16 }}
                    >
                      {toRp(dataUangSaku?.data?.tagihan ?? 0)}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <Splide
        options={{
          type: "loop",
          autoplay: true,
          interval: 4000,
          perPage: 1,
          padding: "2rem",
          arrows: false,
        }}
        className="single-slider tw-mt-8"
      >
        {isLoadingSliders
          ? [1, 2, 3].map((key) => (
              <SplideSlide key={key}>
                <div
                  className="card rounded-l mx-2 text-center shadow-l bg-theme d-flex"
                  style={{ height: 289 }}
                >
                  <Spiner className="!tw-text-gray-500 dark:!tw-text-gray-500 tw-w-8 tw-h-8 m-auto" />
                </div>
              </SplideSlide>
            ))
          : (dataSliders?.data ?? []).map((slider, idx) => (
              <SplideSlide
                key={idx}
                onClick={() => onClickSlider(slider.type, slider.linkAction)}
              >
                <div
                  className="card rounded-l mx-2 text-center shadow-l"
                  style={{
                    height: 289,
                    backgroundImage: `url(${slider.imageUrl})`,
                  }}
                >
                  <div className="card-bottom">
                    <h1 className="font-24 font-700">{slider.title}</h1>
                    <p className="boxed-text-xl">{slider.description}</p>
                  </div>
                  <div className="card-overlay bg-gradient-fade"></div>
                </div>
              </SplideSlide>
            ))}
      </Splide> */}

      {/* <div className="content mb-3 mt-0">
        <h5 className="float-start font-16 font-500">Berita Terkini</h5>
        <Link to="/berita" className="float-end font-12 color-highlight mt-n1">
          Lihat Semua
        </Link>
        <div className="clearfix"></div>
      </div> */}

      {/* <Splide
        options={{
          perPage: 1,
          arrows: false,
        }}
        className="slider-no-dots"
      >
        <SplideSlide className="ps-3 !tw-w-10/12 mb-4">
          <div className="bg-theme pb-3 rounded-m shadow-l overflow-hidden">
            <div className="card mb-1 bg-14 tw-h-[150px]"></div>
            <h4 className="mb-0 ps-2 pe-2 pt-2 font-14 tw-font-medium">
              Prestasi Siswa Meningkat
            </h4>
            <small className="ps-2 pe-2">20 januari 2023 08:23</small>
          </div>
        </SplideSlide>
        <SplideSlide className="ps-3 !tw-w-10/12 mb-4">
          <div className="bg-theme pb-3 rounded-m shadow-l overflow-hidden">
            <div className="card mb-1 bg-14 tw-h-[150px]"></div>
            <h4 className="mb-0 ps-2 pe-2 pt-2 font-14 tw-font-medium">
              Prestasi Siswa Meningkat
            </h4>
            <small className="ps-2 pe-2">20 januari 2023 08:23</small>
          </div>
        </SplideSlide>
        <SplideSlide className="ps-3 !tw-w-10/12 mb-4">
          <div className="bg-theme pb-3 rounded-m shadow-l overflow-hidden">
            <div className="card mb-1 bg-14 tw-h-[150px]"></div>
            <h4 className="mb-0 ps-2 pe-2 pt-2 font-14 tw-font-medium">
              Prestasi Siswa Meningkat
            </h4>
            <small className="ps-2 pe-2">20 januari 2023 08:23</small>
          </div>
        </SplideSlide>
        <SplideSlide className="ps-3 !tw-w-10/12 mb-4">
          <div className="bg-theme pb-3 rounded-m shadow-l overflow-hidden">
            <div className="card mb-1 bg-14 tw-h-[150px]"></div>
            <h4 className="mb-0 ps-2 pe-2 pt-2 font-14 tw-font-medium">
              Prestasi Siswa Meningkat
            </h4>
            <small className="ps-2 pe-2">20 januari 2023 08:23</small>
          </div>
        </SplideSlide>
      </Splide> */}
    </>
  );
};
