import { PopupSuccess } from "@components/generals/popup";
import { Spiner } from "@components/loaders/spiner";
import {
  SettingResetPassword,
  settingResetPasswordSchema,
} from "@components/resolvers/setting.resolvers";
import { yupResolver } from "@hookform/resolvers/yup";
import { AccountService } from "@services/account.service";
import { useNotificationStore } from "@stores/notification.store";
import { useThemeStore } from "@stores/theme.store";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaCheck, FaLock, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const SettingResetPasswordPage = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState<boolean>(false);
  const { setAppBar, setHeader } = useThemeStore((state) => state);
  const { pushNotification } = useNotificationStore((state) => state);
  const { register, handleSubmit } = useForm<SettingResetPassword>({
    resolver: yupResolver<SettingResetPassword>(settingResetPasswordSchema),
  });
  useEffect(() => {
    setAppBar({ title: "Reset Password" });
    setHeader({
      type: "small",
      title: "Reset Password",
      avatar: false,
      buttonBack: true,
    });
    initValidate();
  }, [setAppBar, setHeader]);

  const { mutate, isLoading } = useMutation(AccountService.resetPassword, {
    onSuccess: (response) => {
      if (response.success) {
        setPopup(true);
      } else {
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  return (
    <>
      <div className="card card-style">
        <div className="content mb-0">
          <p className="mb-3">
            Password akan di reset ulang dengan password baru. pastikan untuk
            menyimpan dan mengingat password untuk masuk kedalam akun saat
            keluar dari aplikasi.
          </p>

          <form
            autoComplete="off"
            method="post"
            onSubmit={handleSubmit((data) => mutate(data))}
          >
            <div className="input-style has-borders has-icon validate-field mb-4">
              <i className="lh-1">
                <FaLock />
              </i>
              <input
                type="password"
                className="form-control"
                placeholder="Password Baru"
                {...register("password_new")}
              />
              <label className="color-blue-dark font-10 mt-1">
                Password Baru
              </label>
              <i className="disabled invalid color-red-dark">
                <FaTimes />
              </i>
              <i className="disabled valid color-green-dark">
                <FaCheck />
              </i>
              <em>(*wajib)</em>
            </div>

            <div className="input-style has-borders has-icon validate-field mb-4">
              <i className="lh-1">
                <FaLock />
              </i>
              <input
                type="password"
                className="form-control"
                placeholder="Konfirmasi Password"
                {...register("password_confirm")}
              />
              <label className="color-blue-dark font-10 mt-1">
                Konfirmasi Password
              </label>
              <i className="disabled invalid color-red-dark">
                <FaTimes />
              </i>
              <i className="disabled valid color-green-dark">
                <FaCheck />
              </i>
              <em>(*wajib)</em>
            </div>

            <button
              type="submit"
              className="btn btn-m mt-4 mb-3 btn-full tw-w-full bg-green-dark rounded-sm text-uppercase font-900"
            >
              {isLoading ? <Spiner /> : "Reset Password"}
            </button>
          </form>
        </div>
      </div>
      <PopupSuccess
        show={popup}
        title="Berhasil!"
        message="Password berhasil di reset ulang, pastikan untuk menyimpan password"
        textButton="Kembali"
        onConfirm={() => navigate(-1)}
      />
    </>
  );
};
