import { useThemeStore } from "@stores/theme.store";
import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const StudentAbsencesDetailPage = () => {
  const { state } = useLocation();
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Detail Absen",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Detail Absen", leading: "back" });
  }, [setFooter, setHeader, setAppBar]);

  return (
    <div className="card card-style">
      <div className="content d-flex flex-column mb-0">
        <div className="color-highlight">Tanggal Absen</div>
        <div className="color-theme mb-3">{moment(state.tanggal ?? Date.now()).format("dddd, DD MMMM YYYY | HH:mm")}</div>
        <div className="color-highlight">Kehadiran</div>
        <div className="color-theme mb-3">{state.kehadiran === "H" ? <span className="badge bg-green-dark color-white font-10 tw-py-1 px-2 rounded-2 me-2">HADIR</span> : <span className="badge bg-green-dark color-white font-10 tw-py-1 px-2 rounded-2 me-2">HADIR</span>}</div>
        {(state.sakit ?? 0) > 0 && (
          <>
            <div className="color-highlight">Sakit</div>
            <div className="color-theme mb-3">{state.sakit ?? 0} Jam Pelajaran</div>
          </>
        )}
        {(state.ijin ?? 0) > 0 && (
          <>
            <div className="color-highlight">Izin</div>
            <div className="color-theme mb-3">{state.ijin ?? 0} Jam Pelajaran</div>
          </>
        )}
        {(state.alpa ?? 0) > 0 && (
          <>
            <div className="color-highlight">Alpha</div>
            <div className="color-theme mb-3">{state.alpa ?? 0} Jam Pelajaran</div>
          </>
        )}
        <div className="color-highlight">Catatan</div>
        <div className="color-theme mb-3">{state.catatan ?? "-"}</div>
      </div>
    </div>
  );
};
