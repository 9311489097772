import PreloadLogo from "@assets/images/preload-logo.png";
import { BaseLayout } from "@components/generals/base_layout";
import { Header } from "@components/generals/header";
import { SchoolService } from "@services/school.service";
import { useSchoolStore } from "@stores/school.store";
import { useThemeStore } from "@stores/theme.store";
import { useQuery } from "@tanstack/react-query";
import { androidChangeThemeStatusBar } from "@utils/android";
import { useQueryURL } from "@utils/router";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

export const AuthPage = () => {
  const queryURL = useQueryURL();
  const { themeMode } = useThemeStore((state) => state);
  const { data: infoSchool } = useQuery([SchoolService.queries.GET_SCHOOL_INFO, queryURL.get("prefix") ?? ""], () => SchoolService.info(queryURL.get("prefix") ?? ""));
  const { name, logo, updateInfo } = useSchoolStore((state) => state);

  useEffect(() => {
    if (infoSchool) {
      localStorage.setItem('school-prefix', queryURL.get("prefix") ?? "")
      updateInfo({
        name: infoSchool?.data?.namaLembaga,
        logo: infoSchool?.data?.logoLembaga,
        moto: infoSchool?.data?.motoLembaga,
      });
    }
  }, [infoSchool, updateInfo, queryURL]);

  useEffect(() => {
    if (themeMode === "light") {
      androidChangeThemeStatusBar("#1999FA", "dark");
    }

    if (themeMode === "dark") {
      androidChangeThemeStatusBar("#114C7D", "light");
    }
  }, [themeMode]);

  return (
    <BaseLayout>
      <Header classOverlay="tw-h-[180px]">
        <div className="page-title">
          <div className="tw-pt-24 text-center">
            <img className="tw-w-32 tw-h-32 tw-rounded-sm mb-3" src={logo ?? PreloadLogo} alt="Rounded avatar" />
            <h2>{name ?? "Sistem Informasi Pondok"}</h2>
          </div>
        </div>
      </Header>
      <Outlet />
      {/* <Footer /> */}
    </BaseLayout>
  );
};
