import { BaseService } from "./base.service";
import { BillsAndBalancesResponse, SlidersResponse } from "./dtos/home.dto";

interface HomeServiceInterface {
  queries: {
    GET_SLIDER: string;
    GET_BILLS_AND_BALANCES: string;
  };
  sliders(): Promise<SlidersResponse>;
  billsAndBalances(): Promise<BillsAndBalancesResponse>;
}
export const HomeService: HomeServiceInterface = {
  queries: {
    GET_SLIDER: "GET_SLIDER",
    GET_BILLS_AND_BALANCES: "GET_BILLS_AND_BALANCES",
  },

  sliders: async () => {
    try {
      const request = await BaseService.request().get<SlidersResponse>("/home/sliders");
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  billsAndBalances: async () => {
    try {
      const request = await BaseService.request().get<BillsAndBalancesResponse>("/home/bills-and-balances");
      localStorage.setItem("nis", request.data.data?.nis ?? "");
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
};
