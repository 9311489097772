import AvatarImage from "@assets/images/avatar.png";
import { SpinerPage } from "@components/loaders/spiner";
import { SchoolService } from "@services/school.service";
import { useThemeStore } from "@stores/theme.store";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect } from "react";

export const StudentBioPage = () => {
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);

  const { data, isLoading } = useQuery(
    [SchoolService.queries.GET_SCHOOL_STUDENT_BIO],
    SchoolService.studentBio
  );

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Biodata Santri",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Biodata Santri" });
  }, [setFooter, setHeader, setAppBar]);

  return (
    <>
      <SpinerPage show={isLoading} />
      <div className="card card-style">
        <div className="content mb-0">
          <div className="d-flex">
            <div>
              <img
                src={AvatarImage}
                width="50"
                alt="avatar"
                className="me-3 bg-highlight rounded-xl"
              />
            </div>
            <div>
              <h1 className="mb-0 pt-1">{data?.data?.nama ?? "-"}</h1>
              <p className="color-highlight font-11 mt-n2">
                {data?.data?.kelas ?? "-"}
              </p>
            </div>
          </div>
          <hr className="mb-3" />
          <span className="tw-font-medium tw-text-base">Biodata Lengkap</span>
          <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4 mt-4">
            <input
              type="text"
              disabled={true}
              className="form-control bg-theme"
              placeholder={data?.data?.nama ?? "-"}
            />
            <label htmlFor="form1" className="color-highlight font-400 font-13">
              Nama Lengkap
            </label>
          </div>
          <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
            <input
              type="text"
              disabled={true}
              className="form-control bg-theme"
              placeholder={data?.data?.nis ?? "-"}
            />
            <label htmlFor="form1" className="color-highlight font-400 font-13">
              NIS
            </label>
          </div>
          <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
            <input
              type="text"
              disabled={true}
              className="form-control bg-theme"
              placeholder={data?.data?.kotaLahir ?? "-"}
            />
            <label htmlFor="form1" className="color-highlight font-400 font-13">
              Tempat Lahir
            </label>
          </div>
          <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
            <input
              type="text"
              disabled={true}
              className="form-control bg-theme"
              placeholder={moment(
                data?.data?.tanggalLahir ?? "2023-04-14"
              ).format("DD MMMM YYYY")}
            />
            <label htmlFor="form1" className="color-highlight font-400 font-13">
              Tanggal Lahir
            </label>
          </div>
          <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
            <input
              type="text"
              disabled={true}
              className="form-control bg-theme"
              placeholder={data?.data?.namaAyah ?? "-"}
            />
            <label htmlFor="form1" className="color-highlight font-400 font-13">
              Nama Ayah
            </label>
          </div>
          <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
            <input
              type="text"
              disabled={true}
              className="form-control bg-theme"
              placeholder={data?.data?.namaIbu ?? "-"}
            />
            <label htmlFor="form1" className="color-highlight font-400 font-13">
              Nama Ibu
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
