import { ReactNode } from "react";
import { create } from "zustand";

type AppBarThemeConfig = {
  show?: boolean;
  fixed?: boolean;
  title?: string;
  leading?: "home" | "back";
  actions?: [ReactNode?, ReactNode?, ReactNode?];
};

type HeaderThemeConfig = {
  buttonBack?: boolean;
  title?: ReactNode;
  avatar?: boolean;
  type?: "large" | "small";
};

interface ThemeStore {
  appBar: AppBarThemeConfig;
  header: HeaderThemeConfig;
  footer: boolean;
  bottomNavigation: "hidden" | "home" | "marketplace";
  themeMode: "light" | "dark";
  addListenerThemeMode(): void;
  removeListenerThemeMode(): void;
  setAppBar(config: AppBarThemeConfig): void;
  setHeader(config: HeaderThemeConfig): void;
  setBottomNavigation(status: "hidden" | "home" | "marketplace"): void;
  setFooter(status: boolean): void;
}

export const useThemeStore = create<ThemeStore>((set) => ({
  appBar: {
    show: false,
    fixed: false,
    title: "",
    leading: "home",
    actions: [],
  },
  header: {
    buttonBack: false,
    title: null,
    avatar: true,
    type: "large",
  },
  footer: false,
  bottomNavigation: "hidden",
  themeMode: "light",
  addListenerThemeMode: () => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      document.body.classList.remove("theme-light");
      document.body.classList.add("theme-dark");
      set({ themeMode: "dark" });
    } else {
      document.body.classList.add("theme-light");
      document.body.classList.remove("theme-dark");
      set({ themeMode: "light" });
    }

    const matchMediaThemeMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );

    matchMediaThemeMode.addEventListener("change", (event) => {
      document.body.classList.remove(
        `theme-${event.matches ? "light" : "dark"}`
      );
      document.body.classList.add(`theme-${event.matches ? "dark" : "light"}`);
      set({ themeMode: event.matches ? "dark" : "light" });
    });
  },
  removeListenerThemeMode: () => {
    const matchMediaThemeMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    matchMediaThemeMode.removeEventListener("change", (event) => {
      document.body.classList.remove(
        `theme-${event.matches ? "light" : "dark"}`
      );
      document.body.classList.add(`theme-${event.matches ? "dark" : "light"}`);
      set({ themeMode: event.matches ? "dark" : "light" });
    });
  },
  setAppBar: (config: AppBarThemeConfig) =>
    set((state) => ({ appBar: { ...state.appBar, ...config } })),
  setHeader: (config: HeaderThemeConfig) =>
    set((state) => ({ header: { ...state.header, ...config } })),
  setBottomNavigation: (status: "hidden" | "home" | "marketplace") =>
    set({ bottomNavigation: status }),
  setFooter: (status: boolean) => set({ footer: status }),
}));
