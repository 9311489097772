import { Spiner } from "@components/loaders/spiner";
import { Permit } from "@services/dtos/permit.dto";
import { PermitService } from "@services/permit.service";
import { useThemeStore } from "@stores/theme.store";
import { useQuery } from "@tanstack/react-query";
import { linkPage } from "@utils/router";
import { useEffect, useState } from "react";
import { AiOutlineFileDone } from "react-icons/ai";
import { FaAngleRight, FaChevronDown } from "react-icons/fa";
import { FcFinePrint } from "react-icons/fc";
import { useNavigate } from "react-router-dom";

export const PermitDataPage = () => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState<string>("10");
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);

  const { data, isLoading } = useQuery(
    [PermitService.queries.GET_PERMIT_DATA, limit],
    () => PermitService.data(limit)
  );

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Data Izin",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Data Izin" });
  }, [setFooter, setHeader, setAppBar]);

  const onOpenDetail = (permit: Permit) => {
    navigate(linkPage.PERMIT_DATA_DETAIL, { state: permit });
  };

  return (
    <div className="card card-style">
      <div className="content mb-0">
        <p className="mb-3">
          Data yang ditampilkan hanya {limit} data terakhir
        </p>

        <div className="input-style has-borders input-style-always-active no-icon mb-4">
          <label htmlFor="form5" className="color-highlight font-500">
            Filter Jumlah ditampilkan
          </label>
          <select
            defaultValue="10"
            onChange={(e) => setLimit(e.target.value)}
            className="bg-theme"
          >
            <option value="default" disabled>
              Filter Jumlah ditampilkan
            </option>
            <option value="10">10 Data Terakhir</option>
            <option value="25">25 Data Terakhir</option>
            <option value="50">50 Data Terakhir</option>
            <option value="100">100 Data Terakhir</option>
          </select>
          <span>
            <i>
              <FaChevronDown />
            </i>
          </span>
        </div>

        {isLoading ? (
          <div className="d-flex p-3">
            <Spiner className="!tw-text-gray-200 tw-fill-gray-500 dark:tw-fill-white dark:!tw-text-gray-600 tw-w-8 tw-h-8 m-auto " />
          </div>
        ) : data?.data != null && data.data.length > 0 ? (
          <div className="list-group list-group-flush">
            {data.data.map((item, idx) => (
              <div
                key={idx}
                className="list-group-item tw-bg-inherit d-flex tw-px-0 tw-py-3 tw-border-[rgba(0,0,0,0.05)] dark:tw-border-[rgba(255,255,255,0.05)] tw-cursor-pointer"
                onClick={() => onOpenDetail(item)}
              >
                <div
                  className={`rounded-sm tw-w-8 tw-h-8 d-flex tw-flex-none my-auto ${
                    !item.confirm
                      ? "bg-blue-dark"
                      : item.acc === "Y"
                      ? "bg-green-dark"
                      : "bg-red-dark"
                  }`}
                >
                  <AiOutlineFileDone className="m-auto" size={18} />
                </div>
                <div className="tw-grow mx-3 my-auto d-flex flex-column tw-w-[calc(100vw-140px)]">
                  <span className="tw-font-medium tw-text-sm dark:tw-text-white tw-truncate">
                    {item.mode}
                  </span>
                  <strong className="tw-font-medium tw-text-[11px] tw-leading-4 tw-text-[#adb5bd] tw-truncate">
                    {item.pickup} - {item.pickupStatus}
                  </strong>
                </div>
                <div className="my-auto">
                  <FaAngleRight
                    className="dark:tw-text-white tw-text-gray-500"
                    size={10}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="tw-p-8 text-center ">
            <FcFinePrint size={50} />
            <div>Data tidak ditemukan</div>
          </div>
        )}
      </div>
    </div>
  );
};
