import { ObjectSchema, number, object, string } from "yup";

// ------------- FILTER PRODUCT -------------
export type FilterProduct = {
  search?: string;
  category?: string;
  merchant?: string;
};

export const filterProductSchema: ObjectSchema<FilterProduct> = object({
  search: string().optional(),
  category: string().optional(),
  merchant: string().optional(),
});
// ------------- FILTER PRODUCT -------------

// ------------- FILTER PRODUCT -------------
export type Checkout = {
  amount: number;
  paymentMethod: string;
  paymentFee: number;
};

export const checkoutSchema: ObjectSchema<Checkout> = object({
  amount: number().required(),
  paymentMethod: string().required(),
  paymentFee: number().required(),
});
// ------------- FILTER PRODUCT -------------
