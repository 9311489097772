import { Spiner } from "@components/loaders/spiner";
import { PocketHistory } from "@services/dtos/finance.dto";
import { FinanceService } from "@services/finance.service";
import { useThemeStore } from "@stores/theme.store";
import { useQuery } from "@tanstack/react-query";
import { toRp } from "@utils/helper";
import { linkPage } from "@utils/router";
import moment from "moment";
import { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { FcFinePrint } from "react-icons/fc";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

export const CheckingAccountPage = () => {
  const navigate = useNavigate();
  const [range, setRange] = useState<"WEEK" | "MONTH">("WEEK");
  const [type, setType] = useState<"ALL" | "IN" | "OUT">("ALL");
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);

  const { data, isLoading } = useQuery(
    [FinanceService.queries.GET_FINANCE_CHECKING_ACCOUNT, range, type],
    () => FinanceService.checkingAccount({ range, type })
  );

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Rekening Koran",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ leading: "back", title: "Rekening Koran" });
  }, [setFooter, setHeader, setAppBar]);

  const onOpenDetail = (checkingAccount: PocketHistory) => {
    navigate(linkPage.FINANCE_CHECKING_ACCOUNT_DETAIL, {
      state: checkingAccount,
    });
  };

  return (
    <div className="card card-style">
      <div className="content mb-0">
        <p className="mb-3">
          Data di filter dari{" "}
          {moment()
            .subtract(
              1,
              (range.toLowerCase() +
                "s") as moment.unitOfTime.DurationConstructor
            )
            .format("DD MMMM YYYY")}{" "}
          - {moment().format("DD MMMM YYYY")}
        </p>

        <div className="input-style has-borders input-style-always-active no-icon mb-4">
          <label htmlFor="form5" className="color-highlight font-500">
            Filter Range
          </label>
          <select
            onChange={(e) => setRange(e.target.value as "WEEK" | "MONTH")}
            defaultValue="WEEK"
            className="bg-theme"
          >
            <option value="default" disabled>
              Filter Range
            </option>
            <option value="WEEK">7 Hari Terakhir</option>
            <option value="MONTH">30 Hari Terakhir</option>
          </select>
          <span>
            <i>
              <FaChevronDown/>
            </i>
          </span>
        </div>
        <div className="input-style has-borders input-style-always-active no-icon mb-4">
          <label htmlFor="form5" className="color-highlight font-500">
            Tipe Transaksi
          </label>
          <select
            onChange={(e) => setType(e.target.value as "ALL" | "IN" | "OUT")}
            defaultValue="ALL"
            className="bg-theme"
          >
            <option value="default" disabled>
              Tipe Transaksi
            </option>
            <option value="ALL">Semua Transaksi</option>
            <option value="IN">Uang Masuk</option>
            <option value="OUT">Uang Keluar</option>
          </select>
          <span>
            <i>
              <FaChevronDown/>
            </i>
          </span>
        </div>

        {isLoading ? (
          <div className="d-flex p-3">
            <Spiner className="!tw-text-gray-200 tw-fill-gray-500 dark:tw-fill-white dark:!tw-text-gray-600 tw-w-8 tw-h-8 m-auto " />
          </div>
        ) : data?.data != null && data.data.length > 0 ? (
          <div className="list-group list-group-flush">
            {data.data.map((item, idx) => (
              <div
                key={idx}
                className="list-group-item tw-bg-inherit d-flex tw-px-0 tw-py-3 tw-border-[rgba(0,0,0,0.05)] dark:tw-border-[rgba(255,255,255,0.05)] tw-cursor-pointer"
                onClick={() => onOpenDetail(item)}
              >
                <div
                  className={`rounded-sm tw-w-8 tw-h-8 d-flex tw-flex-none my-auto ${
                    item.filter === "IN" ? "bg-green-dark" : "bg-red-dark"
                  }`}
                >
                  <RiMoneyDollarCircleFill className="m-auto" size={18} />
                </div>
                <div className="tw-grow mx-3 my-auto d-flex flex-column tw-w-[calc(100vw-140px)]">
                  <strong className="tw-font-medium tw-text-sm dark:tw-text-white tw-truncate">
                    {item.filter === "IN" ? "Uang Masuk" : "Uang Keluar"}
                  </strong>
                  <strong className="tw-font-normal tw-text-[11px] tw-leading-4 tw-text-[#adb5bd] tw-truncate">
                  {item.trxId ?? '-'}
                  </strong>
                </div>
                <div className="my-auto tw-flex tw-flex-col">
                  <strong className="tw-font-normal tw-text-[11px] tw-leading-4 tw-text-[#adb5bd] tw-truncate">
                    {moment(item.updateAt).format("DD MMM YYYY")}
                  </strong>
                  <span className="tw-font-bold tw-text-sm tw-truncate">
                    {toRp(item.nominal)}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="tw-p-8 text-center ">
            <FcFinePrint size={50} />
            <div>Data tidak ditemukan</div>
          </div>
        )}
      </div>
    </div>
  );
};
