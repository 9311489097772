import { PopupConfirm } from "@components/generals/popup";
import { SpinerPage } from "@components/loaders/spiner";
import { FinanceService } from "@services/finance.service";
import { useNotificationStore } from "@stores/notification.store";
import { useThemeStore } from "@stores/theme.store";
import { useMutation } from "@tanstack/react-query";
import { toRp } from "@utils/helper";
import copy from "copy-to-clipboard";
import moment from "moment";
import { useEffect, useState } from "react";
import { IoCopy } from "react-icons/io5";
import { useLocation } from "react-router-dom";

export const VirtualAccountReportDetailPage = () => {
  const { state } = useLocation();
  const { pushNotification } = useNotificationStore((state) => state);
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);
  const [status, setStatus] = useState<string>(state?.vaStatus);
  const [trxID, setTrxID] = useState<string>("");
  const [popupCancel, setPopupCancel] = useState<boolean>(false);

  const { mutate, isLoading } = useMutation(FinanceService.virtualAccountCancel, {
    onSuccess: (response) => {
      if (response.success) {
        setStatus("EXPIRED");
        setTrxID('')
        pushNotification({
          type: "success",
          message: "Virtual account berhasil di batalkan",
        });
      } else {
        pushNotification({
          type: "error",
          message: response.message,
        });
      }
    },
    onError: (error: any) => {
      pushNotification({
        type: "error",
        message: error.message ?? "Internal Error",
      });
    },
  });

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Detail Laporan VA",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Detail Laporan VA", leading: "back" });
  }, [setFooter, setHeader, setAppBar]);

  const onCopy = () => {
    copy(state?.vaNumber);
    pushNotification({
      type: "success",
      message: "Nomor VA berhasil di copy!",
    });
  };

  const onOpenPopupCancel = (id: string) => {
    setTrxID(id)
    setPopupCancel(true)
  }

  const onCancel = () => {
    setPopupCancel(false)
    mutate(trxID)
  }

  return (
    <>
      <SpinerPage show={isLoading} />
      <div className="card card-style">
        <div className="content d-flex flex-column mb-0">
          <div className="color-highlight">ID Transaksi</div>
          <div className="color-theme mb-3">{state?.vaTrxId ?? "-"}</div>
          <div className="color-highlight">Nomor Virtual Account</div>
          <div className="color-magenta-dark mb-3 tw-text-lg tw-font-semibold tw-cursor-pointer" onClick={onCopy}>
            {state?.vaNumber ?? "-"}&nbsp;
            <i>
              <IoCopy />
            </i>
          </div>
          <div className="color-highlight">Atas Nama</div>
          <div className="color-theme mb-3">{state?.vaName ?? "-"}</div>
          <div className="color-highlight">Nama Bank</div>
          <div className="color-theme mb-3">{state?.vaBankName ?? "-"}</div>
          <div className="color-highlight">Status Transaki VA</div>
          <div className="color-theme mb-3">
            {status === "PENDING" ? (
              <span className="badge bg-orange-dark color-white font-10 tw-py-1 px-2 rounded-2">PENDING</span>
            ) : status === "COMPLETED" ? (
              <span className="badge bg-green-dark color-white font-10 tw-py-1 px-2 rounded-2">SELESAI</span>
            ) : (
              <span className="badge bg-red-dark color-white font-10 tw-py-1 px-2 rounded-2">KADALUARSA</span>
            )}
          </div>
          <div className="color-highlight">Nominal Transaksi</div>
          <div className="color-theme mb-3">{toRp(state?.vaTrxAmount ?? 0)}</div>
          <div className="color-highlight">Biaya Admin Bank</div>
          <div className="color-theme mb-3">{toRp(state?.vaBankFee ?? 0)}</div>
          <div className="color-highlight">Total</div>
          <div className="color-theme mb-3">{toRp((state?.vaTrxAmount ?? 0) + (state?.vaBankFee ?? 0))}</div>
          <div className="color-highlight">Waktu Kadaluarsa</div>
          <div className="color-theme mb-3">{state?.vaDateExpired != null ? moment(state!.vaDateExpired).format("dddd, DD MMMM YYYY | HH:mm") : "-"}</div>
          <div className="color-highlight">Waktu Bayar</div>
          <div className="color-theme mb-3">{state?.vaDatePayment != null ? moment(state!.vaDatePayment).format("dddd, DD MMMM YYYY | HH:mm") : "-"}</div>
        </div>
      </div>

      {status === "PENDING" && (
        <div className="card card-style !tw-mb-4">
          <div className="content">
            <button className="btn btn-sm btn-full rounded-xs text-uppercase font-900 shadow-s bg-red-dark tw-grow tw-w-full tw-h-11" onClick={() => onOpenPopupCancel(state?.vaTrxId ?? "")}>
              Batalkan Pembayaran
            </button>
          </div>
        </div>
      )}
      <PopupConfirm
        height={200}
        show={popupCancel}
        title="Batalkan Transaksi ?"
        message="Jika di batalkan maka status transaksi menjadi kadaluarsa dan tidak bisa di gunakan kembali"
        textConfirm="Ya, Batalkan"
        textCancel="Kembali"
        onConfirm={onCancel}
        onCancel={() => setPopupCancel(false)}
      />
    </>
  );
};
