import {
  AuthForgotPassword,
  AuthLogin,
  AuthUpdateAccount,
} from "@components/resolvers/auth.resolvers";
import Cookies from "js-cookie";
import moment from "moment";
import { BaseService } from "./base.service";
import { AuthLoginResponse } from "./dtos/auth.dto";
import { BaseResponse } from "./dtos/base.dto";

interface AuthServiceInterface {
  login(payload: AuthLogin): Promise<AuthLoginResponse>;
  forgotPassword(payload: AuthForgotPassword): Promise<BaseResponse<boolean>>;
  resetPassword(payload: {
    code: string;
    password: string;
  }): Promise<BaseResponse<boolean>>;
  updateAccount(payload: AuthUpdateAccount): Promise<AuthLoginResponse>;
}
export const AuthService: AuthServiceInterface = {
  login: async (payload) => {
    try {
      const request = await BaseService.request().post<AuthLoginResponse>(
        "/auth/login",
        payload
      );
      const response = request.data.data!;
      const token = response.token.type + " " + response.token.token;

      Cookies.set("token", token, {
        expires: moment(response.token.eat).toDate(),
      });

      Cookies.set("redirect", response.redirect, {
        expires: moment(response.token.eat).toDate(),
      });

      // androidSaveDataBiomatric(payload);

      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  forgotPassword: async (payload) => {
    try {
      const request = await BaseService.request().post<BaseResponse<boolean>>(
        "/auth/forgot-password",
        payload
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  resetPassword: async (payload) => {
    try {
      const request = await BaseService.request().post<BaseResponse<boolean>>(
        "/auth/reset-password",
        payload
      );
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
  updateAccount: async (payload) => {
    try {
      const request = await BaseService.request().post<BaseResponse<boolean>>(
        "/auth/update-account",
        payload
      );

      Cookies.set("redirect", "/home/dashboard");
      return BaseService.response(request);
    } catch (error: any) {
      return BaseService.response(error, true);
    }
  },
};
