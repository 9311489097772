import { ObjectSchema, number, object, string } from "yup";

// ------------- ACTIVITY REQUEST -------------
export type ActivityRequest = {
  activityId: number;
  pickup: string;
  cost: number;
  paymentMethod: string;
};

export const activityRequestSchema: ObjectSchema<ActivityRequest> = object({
  activityId: number().required(),
  pickup: string().required(),
  cost: number().required(),
  paymentMethod: string().required(),
});
// ------------- ACTIVITY REQUEST -------------