import { ObjectSchema, object, string } from "yup";

// ------------- LOGIN -------------
export type AuthLogin = {
  username: string;
  password: string;
};

export const authLoginSchema: ObjectSchema<AuthLogin> = object({
  username: string().required(),
  password: string().required(),
});
// ------------- LOGIN -------------

// -------- FORGOT PASSWORD --------
export type AuthForgotPassword = {
  email: string;
};

export const authForgotPasswordSchema: ObjectSchema<AuthForgotPassword> =
  object({
    email: string().email().required(),
  });
// -------- FORGOT PASSWORD --------

// --------- UPDATE ACCOUNT --------
export type AuthUpdateAccount = {
  email: string;
  nohp: string;
  password: string;
};

export const authUpdateAccountSchema: ObjectSchema<AuthUpdateAccount> = object({
  email: string().email().required(),
  nohp: string().required(),
  password: string().required(),
});
// --------- UPDATE ACCOUNT --------
