import { ObjectSchema, number, object } from "yup";

// ------------- POCKET TRANSFER -------------
export type PocketTransfer = {
  from: number;
  to: number;
  amount: number;
};

export const pocketTransferSchema: ObjectSchema<PocketTransfer> = object({
  from: number().required(),
  to: number().required(),
  amount: number().required(),
});
// ------------- POCKET TRANSFER -------------