import { ObjectSchema, object, string } from "yup";

// ------------- RESET PASSWORD -------------
export type SettingResetPassword = {
  password_new: string;
  password_confirm: string;
};

export const settingResetPasswordSchema: ObjectSchema<SettingResetPassword> =
  object({
    password_new: string().required(),
    password_confirm: string().required(),
  });
// ------------- RESET PASSWORD -------------

// ------------- UPDATE ACCOUNT -------------
export type SettingUpdateAccount = {
    username: string;
    nohp: string;
    email: string;
};

export const settingUpdateAccountSchema: ObjectSchema<SettingUpdateAccount> =
  object({
    username: string().required(),
    nohp: string().required(),
    email: string().email().required(),
  });
// ------------- UPDATE ACCOUNT -------------
