import { useThemeStore } from "@stores/theme.store";
import { toRp } from "@utils/helper";
import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const BillDetailPage = () => {
  const { state } = useLocation();
  const { setFooter, setHeader, setAppBar } = useThemeStore((state) => state);

  useEffect(() => {
    setFooter(false);
    setHeader({
      type: "small",
      title: "Detail Tagihan",
      buttonBack: true,
      avatar: true,
    });
    setAppBar({ title: "Detail Tagihan", leading: "back" });
  }, [setFooter, setHeader, setAppBar]);

  return (
    <div className="card card-style">
      <div className="content d-flex flex-column mb-0">
        <div className="color-highlight">Tipe Tagihan</div>
        <div className="color-theme mb-3">{state?.tipe ?? "-"}</div>
        <div className="color-highlight">Tagihan</div>
        <div className="color-theme mb-3">{state?.tagihan ?? "-"}</div>
        <div className="color-highlight">Total Tagihan</div>
        <div className="color-theme mb-3">{toRp(state?.nominal ?? 0)}</div>
        <div className="color-highlight">Sisa Tagihan</div>
        <div className="color-theme mb-3">{toRp(state?.sisabayar ?? 0)}</div>
        <div className="color-highlight">Status Tagihan</div>
        <div className="color-theme mb-3">
          {state.terbayar === "Y" ? (
            <span className="badge bg-green-dark color-white font-10 tw-py-1 px-2 rounded-2">
              LUNAS
            </span>
          ) : (
            <span className="badge bg-red-dark color-white font-10 tw-py-1 px-2 rounded-2">
              BELUM LUNAS
            </span>
          )}
        </div>
        <div className="color-highlight">Tahun Ajaran</div>
        <div className="color-theme mb-3">{state?.tahunajaran ?? "-"}</div>
        <div className="color-highlight">Jatuh Tempo</div>
        <div className="color-theme mb-3">
          {state?.jatuhTempo != null
            ? moment(state!.jatuhTempo).format("DD MMMM YYYY")
            : "-"}
        </div>
      </div>
    </div>
  );
};
